const state = {
  survey_search_filter: null
}

const mutations = {
  SET_SURVEY_SEARCH_FILTER: (state, filter) => {
    state.survey_search_filter = filter
  }
}

const actions = {
  updateSurveySearchFilter({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit('SET_SURVEY_SEARCH_FILTER', filters)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

