import request from '@/utils/request'
import store from '@/store'

export function getUserById(user_id) {
  return request({
    url: '/api/v1/users/' + user_id,
    method: 'get'
  })
}

export function getUserPermissionsById(user_id) {
  return request({
    url: '/api/v1/users/' + user_id + '/permissions',
    method: 'get'
  })
}

export function getRecoveryQuestionByToken(token) {
  return request({
    url: '/api/v1/users/token/' + token,
    method: 'get'
  })
}

export function activateUser(data) {
  const body = {
    password: data.password,
    user_pool_id: data.user_pool_id
  }
  return request({
    url: '/api/v1/users/activate/' + data.token,
    method: 'post',
    data: body
  })
}

export function resetPassword(data) {
  const body = {
    token: data.token,
    password: data.password,
    user_pool_id: data.user_pool_id
  }
  return request({
    url: '/api/v1/password/reset',
    method: 'post',
    data: body
  })
}

export async function changePassword(old_password, new_password, new_password_confirmation) {
  const user_id = store.getters.user_id
  const data = {
    old_password: old_password,
    new_password: new_password,
    new_password_confirmation: new_password_confirmation
  }
  return request({
    url: '/api/v1/users/' + user_id + '/attributes/password',
    method: 'patch',
    data: data
  })
}

export async function triggerPasswordReset(email, account_id) {
  return request({
    url: '/api/v1/password/reset/trigger',
    method: 'post',
    data: {
      email: email,
      user_pool_id: account_id
    }
  })
}

export async function triggerWelcomeEmail(user_id) {
  return request({
    url: '/api/v1/users/' + user_id + '/welcome-email',
    method: 'post'
  })
}

export async function updateProfile(first_name, last_name, gender, locale, timezone) {
  const user_id = store.getters.user_id
  const data = {
    first_name: first_name,
    last_name: last_name,
    locale: locale,
    gender: gender,
    timezone: timezone
  }
  return request({
    url: '/api/v1/users/' + user_id,
    method: 'put',
    data: data
  })
}

export async function updateProfilePicture(file) {
  const user_id = store.getters.user_id
  return request({
    url: '/api/v1/users/' + user_id + '/attributes/avatar',
    method: 'post',
    data: {
      'file': file
    }
  })
}
