import hasOffersApp from './has-offers-app'

const install = function(Vue) {
  Vue.directive('has-offers-app', hasOffersApp)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

hasOffersApp.install = install
export default hasOffersApp
