<template>
  <el-drawer
    v-loading="updates.length === 0 && loading"
    custom-class="update-drawer"
    :title="$t('pages.whats_new.headline')"
    :visible.sync="visible"
    direction="rtl"
    :with-header="true"
    :before-close="handleClose"
  >
    <div class="content">
      <span v-for="update in updates" :key="update.id" class="entry">
        <h2>{{ update.title }}</h2>
        <h3 style="margin-top: 0; margin-bottom: 5px;"><small class="date">{{ update.date | msTimestampToDate }}</small></h3>
        <div v-html="update.text" />
        <img :src="update.image_url" alt="" style="width: 100%; height: auto;">
      </span>
    </div>
  </el-drawer>
</template>

<script>
import { listUpdates } from '@/api/v2/updates'
import EventBus from '@/eventbus'

export default {
  name: 'UpdateDrawer',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      updates: [],
      loading: false
    }
  },
  watch: {
    visible: {
      handler() {
        this.loadUpdates()
      }
    }
  },
  created() {
    this.loadUpdates()
  },
  methods: {
    loadUpdates() {
      this.loading = true
      if (this.$store.getters.user_id) {
        const limit = 10
        listUpdates(limit).then(updates => {
          const updateList = updates.data.data
          EventBus.$emit('nr-of-updates', updateList.length)
          this.updates = updateList
        }).catch(() => {
        }).finally(() => {
          this.loading = false
        })
      }
    },
    handleClose(done) {
      this.$emit('hide')
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/element-variables.scss';

.update-drawer >>> header {
  margin-bottom: 10px;
}

.content {
  padding: 0 20px;
}

h2 {
  color: $--color-primary;
  margin-top: 13px;
  margin-bottom: 0;
  line-height: 1;
}

.date {
  color: #d2d2d2;
  font-size: 12px;
}

.entry {
  width: 100%;
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid $--color-info-light;
}
</style>
