<template>
  <el-drawer
    :title="$t('pages.help.headlines.help')"
    :visible.sync="visible"
    direction="rtl"
    :with-header="false"
    :before-close="handleClose"
  >
    <div class="content">
      <span v-if="text" v-html="text" />
    </div>
  </el-drawer>
</template>

<script>

export default {
  name: 'HelpDrawer',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      text: null
    }
  },
  created() {
    this.loadSettings()
  },
  methods: {
    loadSettings() {
      if (this.$store.getters.user_id && !this.$store.getters.help) {
        this.$store.dispatch('help/getHelpText').then(help => {
          this.text = help.text
        }).catch(() => {})
      }
    },
    handleClose(done) {
      this.$emit('hide')
      done()
    }
  }
}
</script>

<style scoped>
.content {
  padding: 20px;
}
</style>
