import { getAllProtocols, getProtocolById, getContactsForProtocol, setWatchStatusForProtocol } from '@/api/protocol'

const state = {
  protocol_search_filter: null
}

const mutations = {
  SET_PROTOCOL_SEARCH_FILTER: (state, filter) => {
    state.protocol_search_filter = filter
  }
}

const actions = {
  updateProtocolSearchFilter({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit('SET_PROTOCOL_SEARCH_FILTER', filters)
      resolve()
    })
  },
  getAllProtocols({ commit }, data = null) {
    return new Promise((resolve, reject) => {
      getAllProtocols(data).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getProtocolById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getProtocolById(id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getContactsForProtocol({ commit }, id) {
    return new Promise((resolve, reject) => {
      getContactsForProtocol(id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setWatchStatusForProtocol({ commit }, data) {
    return new Promise((resolve, reject) => {
      setWatchStatusForProtocol(data.id, data.status).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

