<template>
  <div class="badge">
    <slot />
    <span
      v-show="!hidden && (valTop || valTop === 0 || (isDot && dotPosition === 'top'))"
      class="badge_content"
      :class="[
        isDot ? 'badge_dot_' + topType : 'badge_content_' + topType,
        {
          'is-fixed': isFixed,
          'dot_top': isDot,
          'badge_top': !isDot,
          'is-dot': isDot,
          'style-square': square,
          'style-rounded': !square
        }
      ]"
      v-text="valTop"
    />
    <span
      v-show="!hidden && (valBot || valBot === 0 || (isDot && dotPosition === 'bottom'))"
      class="badge_content"
      :class="[
        isDot ? 'badge_dot_' + bottomType : 'badge_content_' + bottomType,
        {
          'is-fixed': isFixed,
          'dot_bottom': isDot,
          'badge_bottom': !isDot,
          'is-dot': isDot,
          'style-square': square,
          'style-rounded': !square
        }
      ]"
      v-text="valBot"
    />
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    valueTop: {
      type: [String, Number],
      default() {
        return {}
      }
    },
    valueBottom: {
      type: [String, Number],
      default() {
        return null
      }
    },
    max: {
      type: Number,
      default() {
        return 99
      }
    },
    isDot: {
      type: Boolean,
      default() {
        return false
      }
    },
    dotPosition: {
      type: String,
      default() {
        return 'top'
      }
    },
    isFixed: {
      type: Boolean,
      default() {
        return false
      }
    },
    hidden: {
      type: Boolean,
      default() {
        return false
      }
    },
    square: {
      type: Boolean,
      default() {
        return false
      }
    },
    topType: {
      type: String,
      validator(val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1
      },
      default() {
        return 'danger'
      }
    },
    bottomType: {
      type: String,
      validator(val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1
      },
      default() {
        return 'danger'
      }
    }
  },
  data() {
    return {
      valTop: null,
      valBot: null
    }
  },
  created() {
    if (this.isDot) return

    const valueTop = this.valueTop
    const valueBottom = this.valueBottom
    const max = this.max

    let retTop
    let retBot
    if (typeof valueTop === 'number' && typeof max === 'number') {
      retTop = max < valueTop ? `${max}+` : valueTop
    }

    if (typeof valueBottom === 'number' && typeof max === 'number') {
      retBot = max < valueBottom ? `${max}+` : valueBottom
    }

    this.valTop = retTop
    this.valBot = retBot
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.badge {
  position: relative;
  vertical-align: middle;
  display: inline-block;
}

.badge_top {
  position: absolute;
  right: -10px;
  top: -8px;
}

.badge_bottom {
  position: absolute;
  right: -8px;
  bottom: -3px;
}

.dot_top {
  position: absolute;
  right: -12px;
  top: 3px;
}

.dot_bottom {
  position: absolute;
  right: -10px;
  bottom: 0;
}

.badge_content {
  color: #FFF;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  font-weight: bolder;
  text-align: center;
  white-space: nowrap;
  border: 0;
  z-index: 5;
}

.style-rounded {
  border-radius: 10px;
  padding: 0 6px;
  height: 18px;
}

.style-square {
  padding: 10px;
  border-radius: 5px;
  height: 25px;
  line-height: 5px;
}

.badge_content.is-fixed {
  position: absolute;
  top: 0;
  right: 10px;
  -webkit-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%)
}

.badge_content.is-fixed.is-dot {
  right: 5px
}

.badge_content.is-dot {
  height: 10px;
  width: 10px;
  padding: 0;
  right: 0;
  border-radius: 50%;
  z-index: 5;
}

.badge_content_primary {
  background-color: $--color-primary;
  color: white;
}

.badge_content_primary_light {
  background-color: rgb(204,228,234);
  color: $--color-primary;
}

.badge_content_success {
  background-color: rgb(206,247,216);
  color: $--color-success;
}

.badge_content_warning {
  background-color: rgb(255,236,209);
  color: $--color-warning;
}

.badge_content_info {
  background-color: rgb(226,226,226);
  color: $--color-info;
}

.badge_content_danger {
  background-color: rgb(250,211,204);
  color: $--color-danger;
}

.badge_dot_primary {
  background-color: white;
  color: white;
  border: 1px solid black;
}

.badge_dot_primary_light {
  background-color: $--color-primary;
  color: $--color-primary;
  border: 1px solid white;
}

.badge_dot_success {
  background-color: $--color-success;
  color: $--color-success;
  border: 1px solid white;
}

.badge_dot_warning {
  background-color: $--color-warning;
  color: $--color-warning;
  border: 1px solid white;
}

.badge_dot_info {
  background-color: $--color-info;
  color: $--color-info;
  border: 1px solid white;
}

.badge_dot_danger {
  background-color: $--color-danger;
  color: $--color-danger;
  border: 1px solid white;
}
</style>
