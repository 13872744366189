import router from '@/router'
import store from '@/store'

// import { isAuthenticated } from '@/utils/auth' // get token from cookie
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'
import { generateRoutes } from './utils/routes'

NProgress.configure({ showSpinner: true }) // NProgress Configuration

export const noAccountList = [
  /^\/login/,
  /^\/link\/noauth/,
  /^\/register/,
  /^\/login\/[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*/,
  /^\/acc\/[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*/,
  /^\/accounts/,
  /^\/forgot-password/,
  /^\/reset-password\/[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*/,
  /^\/reset-password/,
  /^\/activate/
]

router.beforeEach(async(to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  if (!pathWhitelisted(to.path)) {
    // check if user has an account set
    store.dispatch('account/getCurrentAccount').then(async account => {
      generateRoutes(account.data).then(routes => {
        router.addRoutes(routes)
        // hack method to ensure that addRoutes is complete
        // set the replace: true, so the navigation will not leave a history record
        next({ ...to, replace: true })
      }).catch(() => {
        next()
      })
    }).catch(() => {
      next('/accounts')
    })
  } else {
    next()
  }
})

function pathWhitelisted(path) {
  return noAccountList.find(url => path.match(url))
}
