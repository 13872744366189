/* eslint-disable no-unused-vars */
export enum FeatureFlags {
  PROTOCOLS_APP = 'apps.protocols',
  OFFERS_APP = 'apps.offers',
  TASKS_APP = 'apps.tasks',
  SURVEYS_APP = 'apps.surveys',
  RECURRING_TASKS = 'apps.tasks.recurring',
  CONTACTS_APP = 'apps.contacts',
  SEND_EMAIL_FROM_COMPANY_EMAIL_ADDRESS = 'email.custom_sender_address',
  SEE_CENTRICS_UPDATE_NEWS = 'centrics.update_news',
  EXPORT_CLAIMS = 'feature.export_claims'
}

// @ts-ignore
export default FeatureFlags
