<template>
  <el-dialog
    :visible="true"
    width="35em"
    center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <span class="tc-text-class" v-html="$t('pages.app.messages.tc_and_pp_not_accepted_yet', { pp: transl_pp, tc: transl_tc })" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="logout">{{ $t('pages.app.buttons.logout') }}</el-button>
      <el-button type="primary" @click="accept">{{ $t('pages.app.buttons.accept') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { acceptTC } from '@/api/v2/account'
import store from '@/store'

export default {
  name: 'TermsAcceptedConsent',
  data() {
    return {
      title: this.$t('pages.app.headlines.tc_and_pp_not_accepted_yet'),
      transl_pp: this.$t('pages.app.messages.privacy_policy'),
      transl_tc: this.$t('pages.app.messages.terms_and_conditions')
    }
  },
  created() {
  },
  methods: {
    accept() {
      acceptTC(true).then(() => {
        this.$store.dispatch('account/getCurrentAccount', true).then(() => {
          this.$emit('hide')
        })
      })
    },
    async logout() {
      const account = await this.$store.dispatch('account/getCurrentAccount')
      this.$store.dispatch('auth/logout').then(() => {
        let domain = ''
        if (account && account.data && account.data.domain) {
          domain = account.data.domain
        }
        if (domain) {
          this.$router.push('/login/' + domain)
        } else {
          this.$router.push('/login')
        }
        this.$emit('hide')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/element-variables.scss";

  .tc-text-class {
    word-break: normal;
  }
</style>
