// @ts-ignore
// eslint-disable-next-line no-unused-vars
import Vue, { ObjectDirective } from 'vue'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
// @ts-ignore
import Element from 'element-ui'
import './styles/element-variables.scss'
// @ts-ignore
import App from './App'
import store from '@/store'
import router from '@/router'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.silent = true

if (process.env['NODE_ENV'] !== 'development') {
  // disable console .log in production
  console.log = function() {}
}

import Cookies from 'js-cookie'

// @ts-ignore
import * as directives from '@/directive'
// Register global directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string ]: ObjectDirective })[key])
})

import '@/styles/index.scss' // global css

import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import i18n from './lang' // internationalization

// @ts-ignore
import VueDraggableResizable from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

// @ts-ignore
import FormBuilder from 'element-form-builder'
Vue.use(FormBuilder)

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // Set element-ui default size
  i18n: (key: string, value: string) => i18n.t(key, value)
})
import './utils/fix-el-select-mobile-keyboard'
import * as moment from 'moment'
import 'moment/locale/de-at'
moment.locale('de')

// Register global filter functions
// @ts-ignore
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { // @ts-ignore
    [key: string ]: Function })[key])
})

// modal support
import VModal from 'vue-js-modal'
Vue.use(VModal, { dialog: true, dynamicDefaults: { clickToClose: true }})

// globally import feature flag mixin
import has from '@/mixins/permissions/has.ts'
Vue.mixin(has)

import '@/utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('fa-icon', FontAwesomeIcon)

// globally import permissions mixin
import can from '@/mixins/permissions/can'
Vue.mixin(can)

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
