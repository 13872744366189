import request from '@/utils/request'

export function getContactTypes(filter = null) {
  const query_params = []
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      query_params.push('filter[' + key + ']=' + value)
    }
  }

  return request({
    url: '/api/v1/account_manager/contact_types?' + query_params.join('&'),
    method: 'get'
  })
}

export function updateContactType(obj) {
  const data = {
    title: obj.title,
    short: obj.short
  }
  return request({
    url: '/api/v1/account_manager/contact_types/' + obj.id,
    method: 'put',
    data: data
  })
}

export function storeContactType(obj) {
  const data = {
    title: obj.title,
    short: obj.short
  }
  return request({
    url: '/api/v1/account_manager/contact_types',
    method: 'post',
    data: data
  })
}

export function deleteContactType(id) {
  return request({
    url: '/api/v1/account_manager/contact_types/' + id,
    method: 'delete'
  })
}
