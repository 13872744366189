
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import EventBus from '@/eventbus'
import { open } from '@/utils/routes'
import Badge from '@/components/Badge/index.vue'
import PanThumb from '@/components/PanThumb/index.vue'
@Component({
  components: { PanThumb, Badge }
})
export default class ProfileDropdownMenu extends Vue {
  account:any = null
  isAdmin:boolean = false
  buildnr:number|null = null
  latest_version:number|null = null
  env:string = ''
  currentPath:string = ''
  userPicMale:string = 'https://static.centrics.app/default_avatar_male.jpg'
  clipboard_data:any = {
    at: null,
    as: null
  }

  created() {
    this.currentPath = this.$router.currentRoute.fullPath
    this.buildnr = process.env.VUE_APP_PIPELINE_ID
    this.env = process.env.VUE_APP_ENV
    this.isAdmin = this.$store.getters.roles && this.$store.getters.roles.includes('admin')
    this.getCurrentUser()
    this.nrOfAccounts()
    this.loadClipboardData()
  }

  showHelp() {
    EventBus.$emit('helpVisible', true)
  }

  @Watch('$store.getters.roles')
  watchIsAdminChange(newval:string[], oldval:string[]) {
    this.isAdmin = newval && newval.includes('admin')
  }
  @Watch('$store.getters.latest_version')
  watchLatestUpdateAvailable(newval:number, oldval:number) {
    this.latest_version = newval
  }

  handleDropdownClick(event:string) {
    switch (event) {
      case 'logout':
        this.logout()
        break
    }
  }

  refreshAccountJWT() {
  }

  async loadClipboardData() {
    if (this.$store.getters.user_id) {
      const account = await this.$store.dispatch('account/getCurrentAccount')
      this.clipboard_data.at = this.$store.getters.accessToken
      this.clipboard_data.as = account.signature
    }
  }

  nrOfAccounts() {
    this.$store.dispatch('account/getAllAccounts')
  }

  accountChange(event:any) {
    open('/accounts', event)
  }

  async getCurrentUser() {
    if (this.$store.getters.user_id) {
      await this.$store.dispatch('myuser/getCurrentUser')
      const account = await this.$store.dispatch('account/getCurrentAccount')
      this.account = account.data
    }
  }

  logout() {
    const account = this.account
    this.$store.dispatch('auth/logout').then(() => {
      let domain = ''
      if (account && account.data && account.data.domain) {
        domain = account.data.domain
      }
      this.$router.push('/login/' + domain)
    })
  }
}
