import { render, staticRenderFns } from "./IESupport.vue?vue&type=template&id=f452af6e&scoped=true&"
import script from "./IESupport.vue?vue&type=script&lang=js&"
export * from "./IESupport.vue?vue&type=script&lang=js&"
import style0 from "./IESupport.vue?vue&type=style&index=0&id=f452af6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f452af6e",
  null
  
)

export default component.exports