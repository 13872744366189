<template>
  <div class="has-logo" style="height: 100%">
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%;">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :text-color="variables.menuText"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
      > <!-- :background-color="variables.menuBg" -->
        <sidebar-item v-for="route in normalRoutes" :key="route.path" :item="route" :base-path="route.path" />
        <sidebar-item v-for="route in managerRoutes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
    <hamburger
      id="hamburger-container"
      class="hamburger-container"
      style="font-size: 30px; position: absolute; bottom: 10px; right: 20px;"
    />
    <img v-if="!isCollapse && $store.getters.custom_logo" alt="powered by centrics" :src="$store.getters.powered_by_logo" class="sidebar-logo logo-square" style="height: 50px; position: absolute; bottom: 0; z-index: -1">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import Hamburger from '../../../components/Hamburger/index'

export default {
  components: { Hamburger, SidebarItem, Logo },
  asyncComputed: {
    normalRoutes() {
      const res = []
      this.permission_routes.forEach(async route => {
        if (!route.meta || (route.meta && route.meta.roles && !route.meta.roles.includes('manager'))) {
          res.push(route)
        } else if (route.meta && route.meta.cff) {
          const account = await this.$store.dispatch('account/getCurrentAccount')
          const apps = account.data.cff
          let addRoute = false
          apps.forEach(app => {
            if (route.meta.cff.includes(app)) {
              addRoute = true
            }
          })
          if (addRoute) {
            res.push(route)
          }
        }
      })
      return res
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    managerRoutes() {
      const res = []
      this.permission_routes.forEach(route => {
        if (route.meta) {
          if (route.meta.roles && route.meta.roles.includes('manager')) {
            res.push(route)
          }
        }
      })
      return res
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
