import { clearCachedItems } from '@/utils/storage'
import VueJwtDecode from 'vue-jwt-decode'
import router, { resetRouter } from '../router'
import store from '../store'

export const CURRENT_ACCOUNT_SIGNATURE_ATTR = 'cas'

export function setCurrentAccount(account_signature) {
  return new Promise((resolve, reject) => {
    localStorage.removeItem(CURRENT_ACCOUNT_SIGNATURE_ATTR)
    clearCachedItems()
    localStorage.setItem(CURRENT_ACCOUNT_SIGNATURE_ATTR, account_signature)
    const account_data = VueJwtDecode.decode(account_signature)
    resetRouter()
    store.dispatch('permission/setRoles', account_data.roles)
    const data = {
      roles: account_data.roles,
      cff: account_data.cff
    }
    // generate accessible routes map based on roles
    store.dispatch('permission/generateRoutes', data, { root: true }).then(accessRoutes => {
      // dynamically add accessible routes
      router.addRoutes(accessRoutes)
      resolve(account_signature)
    }).catch(() => {
      reject()
    })
  })
}
