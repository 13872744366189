import request from '@/utils/request'
import moment from 'moment'
import {
  ATTENDEE_FILTER_ATTRIBUTE,
  AUTHOR_FILTER_ATTRIBUTE,
  DATE_FILTER_ATTRIBUTE,
  PUBLISHED_FILTER_ATTRIBUTE,
  SEARCH_FILTER_ATTRIBUTE,
  TEAM_FILTER_ATTRIBUTE
} from '@/config/search_filter/protocol_filter'

export function printProtocolById(id) {
  return request({
    url: '/api/v1/protocols/' + id + '/print',
    method: 'get',
    responseType: 'blob'
  })
}

export function deleteComment(protocol_id, comment_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/comments/' + comment_id,
    method: 'delete'
  })
}

export function changeContactPublic(protocol_id, contact_id, status) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/contacts/' + contact_id,
    method: 'put',
    data: { public: status }
  })
}

export function getFilesForProtocol(protocol_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/files',
    method: 'get'
  })
}

export function deleteFileForProtocol(protocol_id, file_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/files/' + file_id,
    method: 'delete'
  })
}

export function removeContactToProtocolLink(protocol_id, contact_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/contacts/' + contact_id,
    method: 'delete'
  })
}

export function removeTaskToProtocolLink(protocol_id, task_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/tasks/' + task_id,
    method: 'delete'
  })
}

export function getAllProtocols(data = null) {
  const filterAttributes = data.filterAttributes
  const listQuery = data.listQuery

  const params = []
  if (listQuery) {
    if (listQuery.page) {
      params.push('page=' + listQuery.page)
    }
    if (listQuery.limit) {
      params.push('limit=' + listQuery.limit)
    }
    if (listQuery.order) {
      params.push('order=' + listQuery.order)
    }
  }
  if (filterAttributes) {
    if (filterAttributes.date && filterAttributes.date.length) {
      params.push(DATE_FILTER_ATTRIBUTE + '=' + filterAttributes.date.join(','))
    }
    if (filterAttributes.author && filterAttributes.author.length) {
      params.push(AUTHOR_FILTER_ATTRIBUTE + '=' + filterAttributes.author.join(','))
    }
    if (filterAttributes.published && filterAttributes.published.length) {
      params.push(PUBLISHED_FILTER_ATTRIBUTE + '=' + filterAttributes.published.join(','))
    }
    if (filterAttributes.attendees && filterAttributes.attendees.length) {
      params.push(ATTENDEE_FILTER_ATTRIBUTE + '=' + filterAttributes.attendees.join(','))
    }
    if (filterAttributes.teams && filterAttributes.teams.length) {
      params.push(TEAM_FILTER_ATTRIBUTE + '=' + filterAttributes.teams.join(','))
    }
    if (filterAttributes.search) {
      params.push(SEARCH_FILTER_ATTRIBUTE + '=' + filterAttributes.search)
    }
  }

  return request({
    url: '/api/v1/protocols?' + params.join('&'),
    method: 'get'
  })
}

export function searchProtocolByString(search = '') {
  return request({
    url: '/api/v1/protocols?search=' + search,
    method: 'get'
  })
}

export function linkContactToProtocol(contact_id, protocol_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/contacts',
    method: 'post',
    data: { contact_id: contact_id }
  })
}

export function linkTaskToProtocol(task_id, protocol_id) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/tasks',
    method: 'post',
    data: { task_id: task_id }
  })
}

export function getProtocolById(id) {
  return request({
    url: '/api/v1/protocols/' + id,
    method: 'get'
  })
}

export function getContactsForProtocol(id) {
  return request({
    url: '/api/v1/protocols/' + id + '/contacts',
    method: 'get'
  })
}

export function getTasksForProtocol(id, listQuery) {
  const params = []
  if (listQuery) {
    if (listQuery.page) {
      params.push('page=' + listQuery.page)
    }
    if (listQuery.limit) {
      params.push('limit=' + listQuery.limit)
    }
    if (listQuery.order) {
      params.push('order=' + listQuery.order)
    }
  }
  return request({
    url: '/api/v1/protocols/' + id + '/tasks?' + params.join('&'),
    method: 'get'
  })
}

export function deleteProtocol(id) {
  return request({
    url: '/api/v1/protocols/' + id,
    method: 'delete'
  })
}

export function createComment(task_id, body) {
  return request({
    url: '/api/v1/protocols/' + task_id + '/comments',
    method: 'post',
    data: { body: body }
  })
}

export function updateComment(protocol_id, comment_id, body) {
  return request({
    url: '/api/v1/protocols/' + protocol_id + '/comments/' + comment_id,
    method: 'put',
    data: { body: body }
  })
}

export function setWatchStatusForProtocol(id, status) {
  return request({
    url: '/api/v1/protocols/' + id + '/watch',
    method: 'patch',
    data: { status: status }
  })
}

export function addViewer(id, user_id) {
  return request({
    url: '/api/v1/protocols/' + id + '/viewers',
    method: 'post',
    data: { user_id: user_id }
  })
}

export function removeViewer(id, user_id) {
  return request({
    url: '/api/v1/protocols/' + id + '/viewers/' + user_id,
    method: 'delete'
  })
}

/**
 * Delete all null (or undefined) properties from an object.
 * Set 'recurse' to true if you also want to delete properties in nested objects.
 */
function cleanup(test, recurse) {
  for (var i in test) {
    if (test[i] == null || test[i] === '') {
      delete test[i]
    } else if (recurse && typeof test[i] === 'object') {
      cleanup(test[i], recurse)
    }
  }
}
