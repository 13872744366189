import store from '@/store'
import TeamPermissions from '../../enum/Permissions'

const can = {
  methods: {
    teamAdmin: async function(team) {
      await store.dispatch('myuser/getCurrentUser')
      await store.dispatch('myuser/getMyPermissions')
      const permissions = store.getters && store.getters.permissions
      let can = false
      if (permissions) {
        permissions.team_permissions.forEach(t => {
          if (team.team_id === t.team_id) {
            if (team.is_member && team.permissions.includes(TeamPermissions.ADMIN)) {
              can = true
            }
          }
        })
      }
      return can
    },
    editTask: async function(task) {
      const current_user_id = store.getters && store.getters.user_id
      await store.dispatch('myuser/getCurrentUser')
      await store.dispatch('myuser/getMyPermissions')
      const permissions = store.getters && store.getters.permissions
      let can = false
      if (task.author_user_id === current_user_id || task.created_by === current_user_id) {
        return true
      }
      if (permissions) {
        permissions.team_permissions.forEach(team => {
          if (team.team_id === task.team_id) {
            if (team.permissions.includes(TeamPermissions.TASKS_EDIT)) {
              can = true
            }
          }
        })
      }
      return can
    },
    deleteTask: async function(task) {
      const current_user_id = store.getters && store.getters.user_id
      await store.dispatch('myuser/getCurrentUser')
      await store.dispatch('myuser/getMyPermissions')
      const permissions = store.getters && store.getters.permissions
      let can = false
      if (task.author_user_id === current_user_id || task.created_by === current_user_id) {
        return true
      }
      if (permissions) {
        permissions.team_permissions.forEach(team => {
          if (team.team_id === task.team_id) {
            if (team.permissions.includes(TeamPermissions.TASKS_DELETE)) {
              can = true
            }
          }
        })
      }
      return can
    },
    editProtocol: async function(protocol) {
      const current_user_id = store.getters && store.getters.user_id
      await store.dispatch('myuser/getCurrentUser')
      await store.dispatch('myuser/getMyPermissions')
      const permissions = store.getters && store.getters.permissions
      let can = false
      if (protocol.author_user_id === current_user_id || protocol.created_by === current_user_id) {
        return true
      }
      if (permissions) {
        permissions.team_permissions.forEach(team => {
          if (team.team_id === protocol.team_id) {
            if (team.permissions.includes(TeamPermissions.PROTOCOLS_EDIT)) {
              can = true
            }
          }
        })
      }
      return can
    },
    deleteProtocol: async function(protocol) {
      const current_user_id = store.getters && store.getters.user_id
      await store.dispatch('myuser/getCurrentUser')
      await store.dispatch('myuser/getMyPermissions')
      const permissions = store.getters && store.getters.permissions
      let can = false
      if (protocol.author_user_id === current_user_id || protocol.created_by === current_user_id) {
        return true
      }
      if (permissions) {
        permissions.team_permissions.forEach(team => {
          if (team.team_id === protocol.team_id) {
            if (team.permissions.includes(TeamPermissions.PROTOCOLS_DELETE)) {
              can = true
            }
          }
        })
      }
      return can
    }
  }
}

export default can
