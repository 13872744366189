<template>
  <div id="app">
    <cookie-consent />
    <terms-accepted-consent v-if="show_terms && isManager && this.$store.getters.user_id" @hide="show_terms=false" />
    <help-drawer :visible="show_help" @hide="show_help=false" />
    <update-drawer :visible="show_updates" @hide="show_updates=false" />
    <i-e-support v-if="isIE" />
    <router-view />
    <v-dialog />
  </div>
</template>
<script>
import EventBus from './eventbus'
import CookieConsent from './CookieConsent'
import TermsAcceptedConsent from '@/TermsAcceptedConsent'
import IESupport from '@/IESupport'
import HelpDrawer from '@/components/Drawer/HelpDrawer'
import UpdateDrawer from '@/components/Drawer/UpdateDrawer'

export default {
  name: 'App',
  components: { UpdateDrawer, HelpDrawer, IESupport, TermsAcceptedConsent, CookieConsent },
  data: function() {
    return {
      isIE: false,
      isManager: false,
      show_terms: false,
      show_help: false,
      show_updates: false,
      authenticated: false,
      token_loading: false
    }
  },
  watch: {
    // Everytime the route changes, check for auth status
    '$route': 'checkTCAccepted'
  },
  created() {
    if (this.$store.getters.user_id) {
      this.$store.dispatch('users/getUserList')
      this.$store.dispatch('account_settings/getAccountSettings')
      this.isManager = this.$store.getters.roles.includes('manager')
    }

    this.checkTCAccepted()
  },
  mounted() {
    this.checkIfIsIE()
    EventBus.$on('loading-token', this.listener)
    EventBus.$on('helpVisible', this.setHelpVisibility)
    EventBus.$on('updateVisible', this.setUpdatesVisibility)
  },
  beforeDestroy() {
    EventBus.$off('helpVisible', this.setHelpVisibility)
    EventBus.$off('updateVisible', this.setUpdatesVisibility)
    EventBus.$off('loading-token', this.listener)
  },
  methods: {
    checkIfIsIE() {
      const ua = navigator.userAgent
      /* MSIE used to detect old browsers and Trident used to newer ones*/
      this.isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    },
    checkTCAccepted() {
      if (this.$store.getters.user_id) {
        this.$store.dispatch('account/getCurrentAccount').then(account => {
          if (!account.data.terms_accepted) {
            this.show_terms = true
          }
        })
      }
    },
    listener(state) {
      if (this.$router.currentRoute !== '/') {
        this.token_loading = state
      }
    },
    setHelpVisibility(state) {
      this.show_help = state
    },
    setUpdatesVisibility(state) {
      this.show_updates = state
    }
  }
}
</script>
