import store from '@/store'

const isUser = {
  inserted(el, binding, vnode) {
    const roles = store.getters && store.getters.roles

    if (!roles.includes('user')) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default isUser
