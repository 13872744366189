import hasContactsApp from './has-contacts-app'

const install = function(Vue) {
  Vue.directive('has-contacts-app', hasContactsApp)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

hasContactsApp.install = install
export default hasContactsApp
