import { getCountries } from '@/api/static_content'
import { getContactTypes } from '../../api/manager/contact_type'

const state = {
  countries: [],
  contact_types: []
}

const mutations = {
  SET_COUNTRIES: (state, countries) => {
    state.countries = countries
  },
  SET_CONTACT_TYPES: (state, types) => {
    state.contact_types = types
  }
}

const actions = {
  resetState({ commit }) {
    commit('SET_CONTACT_TYPES', [])
    commit('SET_COUNTRIES', [])
  },
  getCountries({ commit }, force_reload = false) {
    return new Promise((resolve, reject) => {
      if (force_reload) {
        commit('SET_COUNTRIES', [])
      }
      if (state.countries.length) {
        resolve(state.user_list)
      } else {
        getCountries().then(resp => {
          if (resp) {
            commit('SET_COUNTRIES', resp.data)
            resolve(resp.data)
          }
        }).catch(err => {
          reject(err)
        })
      }
    })
  },
  getContactTypes({ commit }, force = false) {
    if (force) {
      commit('SET_CONTACT_TYPES', [])
    }
    return new Promise((resolve, reject) => {
      if (state.contact_types.length) {
        resolve(state.contact_types)
      } else {
        getContactTypes().then(resp => {
          if (resp.data.length !== 0) {
            commit('SET_CONTACT_TYPES', resp.data)
          }
          resolve(resp.data)
        }).catch(err => {
          reject(err)
        })
      }
    })
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state
}

