import request from '@/utils/request'

export function getUserById(id) {
  return request({
    url: '/api/v2/users/' + id,
    method: 'get'
  })
}

export function getUsers(list_query, filter, search, unscoped = true) {
  const query_params = []
  if (list_query && list_query.page) {
    query_params.push('page=' + list_query.page)
  }
  if (list_query && list_query.limit) {
    query_params.push('limit=' + list_query.limit)
  }
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      query_params.push('filter[' + key + ']=' + value)
    }
  }
  query_params.push('unscoped=' + unscoped)

  if (search) {
    query_params.push('search=' + search)
  }

  return request({
    url: '/api/v2/users/?' + query_params.join('&'),
    method: 'get'
  })
}

