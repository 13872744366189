import { getHelpText } from '@/api/v2/static'

const state = {
  help: null
}

const mutations = {
  SET_HELP: (state, data) => {
    state.help = {
      text: data.text,
      button: data.button
    }
  }
}

const actions = {
  getHelpText({ commit }) {
    return new Promise((resolve, reject) => {
      getHelpText().then(resp => {
        const data = {
          text: resp.data.data.text,
          button: resp.data.data.button
        }
        commit('SET_HELP', data)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

