export const LOCAL_STORAGE_PREFIX = 'tasks.'

export const SEARCH_FILTER_ATTRIBUTE = 'filter[search]'
export const DUE_DATE_FILTER_ATTRIBUTE = 'filter[due_date]'
export const ASSIGNEE_FILTER_ATTRIBUTE = 'filter[assignee]'
export const USER_STATUS_FILTER_ATTRIBUTE = 'filter[user_status]'
export const AUTHOR_FILTER_ATTRIBUTE = 'filter[author]'
export const PUBLISHED_FILTER_ATTRIBUTE = 'filter[published_status]'
export const STATUS_FILTER_ATTRIBUTE = 'filter[status]'
export const TEAM_FILTER_ATTRIBUTE = 'filter[team]'
export const WITH_RESOLVED_FILTER_ATTRIBUTE = 'filter[hide_resolved]'
