
import { Component, Vue, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { createNewBookmark, deleteBookmark, getMyBookmarks } from '@/api/bookmark'
import { open } from '@/utils/routes'

@Component
export default class BookmarksMenu extends Vue {
  currentPath:string|null = null
  bookmarks:any[] = []
  bookmarks_loading:boolean = false

  beforeMount() {
    this.loadBookmarks()
  }

  @Watch('$route')
  watchRoute(newval:boolean, oldval:boolean) {
    this.currentPath = this.$router.currentRoute.fullPath
  }

  loadBookmarks() {
    this.bookmarks_loading = true
    getMyBookmarks().then(resp => {
      this.bookmarks = resp.data.data
      this.bookmarks_loading = false
    })
  }

  addBookmarkDraft() {
    const page:any = {
      id: uuidv4(),
      label: 'New Bookmark',
      path: this.currentPath,
      edit: true
    }
    this.bookmarks.push(page)
  }

  createBookmark(page:any) {
    page.edit = false
    createNewBookmark(page.path, page.label).then(resp => {
      (this.$refs.bookmarks as any).hide()
      this.loadBookmarks()
    })
  }

  goToPage(path:string) {
    (this.$refs.bookmarks as any).hide()
    open(path)
  }

  deleteBookmark(id:string) {
    deleteBookmark(id).then(resp => {
      this.loadBookmarks()
    })
  }
}
