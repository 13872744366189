import { createContact, getContactById, getContactByNumber, updateContact, getTasksForContact, getProtocolsForContact, uploadLogo, deleteContact } from '@/api/contact'

const actions = {
  deleteContact({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteContact(id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  uploadLogo({ commit }, data) {
    return new Promise((resolve, reject) => {
      uploadLogo(data.contact_id, data.file).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getContactById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getContactById(id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksForContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTasksForContact(data).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getProtocolsForContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      getProtocolsForContact(data).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getContactByNumber({ commit }, id) {
    return new Promise((resolve, reject) => {
      getContactByNumber(id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      createContact(data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateContact(data).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  actions
}

