import Vue from 'vue'
import Router from 'vue-router'
import 'nprogress/nprogress.css' // progress bar style
import constantRoutes from '@/router/modules/constantRoutes'
import store from '@/store'

Vue.use(Router)

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const notAccessibleWithAuth = [
  /^\/login/,
  /^\/register/,
  /^\/login\/.*/,
  /^\/forgot-password/,
  /^\/reset-password\/.*/,
  /^\/reset-password/,
  /^\/activate/
]

router.beforeEach((to, from, next) => {
  if (notAccessibleWithAuth.find(url => to.path.match(url))) {
    // change background color for auth page
    document.body.className = 'auth'
    // if logged in, redirect him to home page
    if (store.getters.accessToken) {
      next('/home')
    }
  } else {
    document.body.className = 'default'
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.refreshToken) {
      next()
    } else {
      // choosing finally here, as in case of an exception, it now still logs out
      store.dispatch('auth/logout').finally(() => {
        next({
          path: '/login'
        })
      })
    }
  }
  next()
})

export default router
