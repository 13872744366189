import axios from 'axios'
import store from '@/store'
import access_token_interceptor from './interceptors/access_token_interceptor'
import account_interceptor from './interceptors/account_interceptor'
import client_version_interceptor from './interceptors/client_version_interceptor'
import { getLanguageIso } from '@/lang'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 1000 * 30, // request timeout,
  headers: {
    'X-Client-Version': process.env.VUE_APP_PIPELINE_ID
  },
  json: true
})

export const noAuthList = [
  /^\/api\/v1\/oauth\/token/,
  /^\/api\/v1\/oauth\/refresh/,
  /^\/api\/v1\/account_infos\/.*/,
  /^\/api\/v1\/users\/activate\/.*/,
  /^\/api\/v1\/oauth\/challenge/,
  /^\/api\/v1\/password\/reset\/trigger/,
  /^\/api\/v1\/password\/reset/,
  /^\/api\/v2\/accounts\/register/
]

export const noAccountList = [
  /^\/api\/v1\/users\/.*\/accounts/,
  /^\/api\/v1\/oauth\/token/,
  /^\/api\/v1\/oauth\/refresh/,
  /^\/api\/v1\/oauth\/challenge/,
  /^\/api\/v1\/users\/activate\/.*/,
  /^\/api\/v1\/account_infos\/.*/,
  /^\/api\/v1\/password\/reset\/trigger/,
  /^\/api\/v1\/password\/reset/,
  /^\/api\/v2\/accounts\/register/
]

service.interceptors.request.use(
  async config => {
    const needAuth = !noAuthList.find(url => config.url.match(url))
    const needAccount = !noAccountList.find(url => config.url.match(url))
    const CancelToken = axios.CancelToken

    if (needAccount) {
      const account = await store.dispatch('account/getCurrentAccount')
      config.headers['Account'] = account.signature
    }
    if (needAuth) {
      if (!store.getters.accessToken || !store.getters.user_id) {
        if (!store.getters.refreshToken) {
          // return { ...config, cancelToken: new CancelToken((cancel) => cancel('NO_AUTH')) }
        }
        store.dispatch('auth/refresh').then(resp => {
          config.headers['Authorization'] = 'Bearer ' + resp.AccessToken
        }).catch(() => {
          // return { ...config, cancelToken: new CancelToken((cancel) => cancel('NO_AUTH')) }
        }).finally(() => {
        })
      }
      // set the access-token for every request
      config.headers['Authorization'] = 'Bearer ' + store.getters.accessToken
    }
    config.headers['Content-Language'] = getLanguageIso()
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

access_token_interceptor(service)
account_interceptor(service)
client_version_interceptor(service)

export default service
