
import { Component, Vue, Watch } from 'vue-property-decorator'
import EventBus from '@/eventbus'
import { open } from '@/utils/routes'
import Badge from '@/components/Badge/index.vue'
import PanThumb from '@/components/PanThumb/index.vue'
import NotificationDot from '@/layout/components/NotificationDot.vue'
import FeatureFlags from '@/enum/FeatureFlags'
@Component({
  components: { NotificationDot, PanThumb, Badge }
})
export default class HelpDropdownMenu extends Vue {
  account:any = null
  isAdmin:boolean = false
  hasUpdateFlag:boolean = false
  updateAvailable:boolean = false
  infoAvailable!:boolean
  dropdownOpened:boolean = false
  nrOfNotifications:number = 1
  nrOfUpdates:number = 0

  created() {
    this.isAdmin = this.$store.getters.roles && this.$store.getters.roles.includes('admin')
    this.getCurrentUser()
    this.getFeatureFlags()
    const self = this
    EventBus.$on('nr-of-updates', this.updateNrOfUpdates)
  }

  // just to make typescript compiler happy. actual implementation is in global mixin loaded in main.ts
  public hasFeatureFlag: any
  private async getFeatureFlags() {
    this.hasUpdateFlag = await this.hasFeatureFlag(FeatureFlags.SEE_CENTRICS_UPDATE_NEWS)
  }

  beforeDestroy() {
    EventBus.$off('nr-of-updates', this.updateNrOfUpdates)
  }

  updateNrOfUpdates(val:number) {
    if (val) {
      this.nrOfUpdates = val
    }
  }

  toggleDropdown(event:any) {
    this.dropdownOpened = event
  }

  showHelp() {
    EventBus.$emit('helpVisible', true)
  }

  showUpdates() {
    EventBus.$emit('updateVisible', true)
  }

  @Watch('$store.getters.roles')
  watchIsAdminChange(newval:string[], oldval:string[]) {
    this.isAdmin = newval && newval.includes('admin')
  }

  @Watch('$store.getters.update_available')
  watchUpdateAvailable(newval:boolean, oldval:boolean) {
    if (newval) {
      // only set to true. false is default. after installing update, this is set to false again.
      this.updateAvailable = newval
    }
  }

  handleDropdownClick(event:string) {
    switch (event) {
      case 'refresh':
        this.installUpdate()
        break
      case 'about':
        this.aboutRedirect()
        break
      case 'consents':
        (window as any).klaro.render((window as any).klaroConfig, { show: true })
        break
      case 'whats_new':
        this.showUpdates()
        break
    }
  }

  nrOfAccounts() {
    this.$store.dispatch('account/getAllAccounts')
  }

  accountChange(event:any) {
    open('/accounts', event)
  }

  installUpdate() {
    const url:string = window.location.href
    window.location.href = url
  }

  aboutRedirect() {
    window.open('https://centrics.io', '_blank')
  }

  async getCurrentUser() {
    if (this.$store.getters.user_id) {
      await this.$store.dispatch('myuser/getCurrentUser')
      const account = await this.$store.dispatch('account/getCurrentAccount')
      this.account = account.data
    }
  }
}
