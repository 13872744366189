/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout'
import FeatureFlags from '../../enum/FeatureFlags'
import store from '../../store'

const asyncRoutes = [
  {
    path: '/manager',
    component: Layout,
    redirect: '/manager/account',
    meta: {
      requiresAuth: true,
      icon: 'sliders-h',
      roles: ['manager'] // you can set roles in root nav
    },
    children: [
      {
        title: 'account_management',
        path: '/manager/account',
        component: () => import('@/views/manager/account'),
        meta: { title: 'account_management' }
      }
    ]
  },
  {
    path: '/manager/teams',
    component: Layout,
    meta: {
      requiresAuth: true,
      icon: 'user-friends',
      roles: ['team_admin', 'manager']
    },
    children: [
      {
        path: '/manager/teams',
        component: () => import('@/views/manager/teams'),
        meta: { title: 'account_management_teams' }
      }
    ]
  },
  {
    path: '/contacts',
    component: Layout,
    meta: {
      requiresAuth: true,
      roles: ['user'],
      cff: [FeatureFlags.CONTACTS_APP]
    },
    children: [
      {
        path: '',
        component: () => import('@/views/contacts/list'),
        meta: {
          title: 'contacts',
          icon: 'address-book',
          noCache: true
        }
      },
      {
        path: ':id',
        component: () => import('@/views/contacts/view'),
        hidden: true,
        meta: { title: 'contacts' }
      }
    ]
  },
  {
    path: '/surveys',
    component: Layout,
    meta: {
      requiresAuth: true,
      roles: ['surveys'],
      cff: [FeatureFlags.SURVEYS_APP]
    },
    children: [
      {
        path: '',
        component: () => import('@/views/surveys/list'),
        meta: {
          title: 'surveys',
          icon: 'vote-yea',
          noCache: true
        }
      },
      {
        path: 'new',
        component: () => import('@/views/surveys/new'),
        hidden: true,
        beforeEnter(to, from, next) {
          if (store.getters.roles.includes('surveys')) {
            next()
          } else {
            next('/home')
          }
        },
        meta: {
          noCache: true,
          title: 'New',
          roles: ['surveys']
        }
      },
      {
        path: ':id',
        hidden: true,
        beforeEnter(to, from, next) {
          if (store.getters.roles.includes('surveys')) {
            next()
          } else {
            next('/home')
          }
        },
        component: () => import('@/views/surveys/new'),
        meta: { title: 'surveys' }
      }
    ]
  },
  {
    path: '/tasks',
    component: Layout,
    meta: {
      requiresAuth: true,
      roles: ['user', 'contact'],
      cff: [FeatureFlags.TASKS_APP],
      title: 'tasks'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/tasks/list'),
        meta: {
          icon: 'tasks',
          noCache: true
        }
      },
      {
        path: 'new',
        component: () => import('@/views/tasks/new'),
        hidden: true,
        meta: {
          noCache: true,
          title: 'new'
        }
      },
      {
        path: ':id',
        component: () => import('@/views/tasks/view'),
        hidden: true,
        meta: {
          noCache: true,
          title: ''
        }
      }
    ]
  },
  {
    path: '/protocols',
    component: Layout,
    meta: {
      requiresAuth: true,
      roles: ['user', 'contact'],
      cff: [FeatureFlags.PROTOCOLS_APP],
      title: 'protocols'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/protocols/list'),
        meta: {
          icon: 'clipboard-list',
          noCache: true
        }
      },
      {
        path: 'new',
        component: () => import('@/views/protocols/new'),
        hidden: true,
        beforeEnter(to, from, next) {
          if (store.getters.roles.includes('user')) {
            next()
          } else {
            next('/home')
          }
        },
        meta: {
          noCache: true,
          title: 'New'
        }
      },
      {
        path: ':id',
        component: () => import('@/views/protocols/view'),
        hidden: true,
        meta: {
          title: ''
        }
      }
    ]
  },
  {
    path: '/offers',
    component: Layout,
    meta: {
      requiresAuth: true,
      roles: ['user'],
      cff: [FeatureFlags.OFFERS_APP],
      title: 'offers'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/offers/list'),
        meta: {
          icon: 'money-check',
          noCache: true
        }
      },
      {
        path: 'new',
        component: () => import('@/views/offers/new'),
        hidden: true,
        beforeEnter(to, from, next) {
          if (store.getters.roles.includes('user')) {
            next()
          } else {
            next('/home')
          }
        },
        meta: {
          noCache: true,
          title: 'New'
        }
      },
      {
        path: ':id',
        component: () => import('@/views/offers/view'),
        hidden: true,
        meta: {
          title: ''
        }
      }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    redirect: '/manager/account',
    hidden: true,
    meta: {
      title: 'admin',
      requiresAuth: true,
      icon: 'el-icon-s-operation',
      roles: ['admin'] // you can set roles in root nav
    },
    children: [
      {
        path: 'accounts',
        component: () => import('@/views/admin/list'),
        meta: { title: 'admin' },
        hidden: true
      },
      {
        path: 'accounts/:id',
        component: () => import('@/views/admin/view'),
        hidden: true,
        meta: { title: 'admin' }
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

export default asyncRoutes
