<template>
  <el-dialog
    :visible="true"
    :title="$t('pages.app.headlines.ie_not_supported')"
    width="35em"
    center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <span class="tc-text-class" v-html="$t('pages.app.messages.ie_not_supported')" />
  </el-dialog>
</template>

<script>

import { acceptTC } from '@/api/v2/account'
import store from '@/store'

export default {
  name: 'IESupport'
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/element-variables.scss";

  .tc-text-class {
    word-break: normal;
  }
</style>
