import store from '@/store'

const isNotContact = {
  inserted(el, binding, vnode) {
    let roles = store.getters && store.getters.roles
    if (binding.value) {
      roles = binding.value
    }
    if (roles.includes('contact')) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default isNotContact
