import hasTasksApp from './has-tasks-app'

const install = function(Vue) {
  Vue.directive('has-tasks-app', hasTasksApp)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

hasTasksApp.install = install
export default hasTasksApp
