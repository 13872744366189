import store from '@/store'

const isAdmin = {
  inserted(el, binding, vnode) {
    let roles = store.getters && store.getters.roles
    if (binding.value) {
      roles = binding.value
    }

    if (!roles.includes('admin')) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default isAdmin
