import { render, staticRenderFns } from "./HelpDropdownMenu.vue?vue&type=template&id=fcf648fe&scoped=true&"
import script from "./HelpDropdownMenu.vue?vue&type=script&lang=ts&"
export * from "./HelpDropdownMenu.vue?vue&type=script&lang=ts&"
import style0 from "./HelpDropdownMenu.vue?vue&type=style&index=0&id=fcf648fe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcf648fe",
  null
  
)

export default component.exports