import store from '@/store'

const isTeamAdmin = {
  inserted(el, binding, vnode) {
    const roles = store.getters && store.getters.roles

    if (!roles.includes('team_admin')) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default isTeamAdmin
