import request from '@/utils/request'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export function getAllContacts(data = null) {
  const params = []
  if (data) {
    const filterAttributes = data.filterAttributes
    const listQuery = data.listQuery
    if (listQuery) {
      params.push('page=' + listQuery.page)
      params.push('limit=' + listQuery.limit)
      if (listQuery.order) {
        params.push('order=' + listQuery.order)
      }
    }
    if (filterAttributes) {
      if (filterAttributes.number) {
        params.push('number=' + filterAttributes.number)
      }
      if (filterAttributes.name) {
        params.push('name=' + filterAttributes.name)
      }
      if (filterAttributes.type) {
        params.push('type=' + filterAttributes.type)
      }
    }
  }
  return request({
    url: '/api/v1/contacts?' + params.join('&'),
    method: 'get'
  })
}

export function searchContactByString(search = '') {
  return request({
    url: '/api/v1/contacts?search=' + search,
    method: 'get'
  })
}

export function createContact(data) {
  // clearing null empty values
  cleanup(data)
  if (data.contact_since) {
    data.contact_since = moment(data.contact_since).format('Y-M-D')
  }

  const body = {
    id: uuidv4(),
    name: data.name,
    account_manager: data.account_manager,
    key_account_manager: data.key_account_manager,
    number: data.number !== undefined ? String(data.number) : null,
    uid: data.uid,
    type: data.type,
    homepage: data.homepage,
    address: {
      zip: data.address.zip,
      street: data.address.street,
      country: data.address.country,
      city: data.address.city
    },
    contact_since: data.contact_since
  }

  return request({
    url: '/api/v1/contacts',
    method: 'post',
    data: body
  })
}

export async function uploadLogo(contact_id, file) {
  return request({
    url: '/api/v1/contacts/' + contact_id + '/logo',
    method: 'post',
    data: {
      'file': file
    }
  })
}

export async function deleteContact(contact_id) {
  return request({
    url: '/api/v1/contacts/' + contact_id,
    method: 'delete'
  })
}

export function updateContact(data) {
  // clearing null empty values
  cleanup(data)
  const body = {
    name: data.name,
    number: data.number !== undefined ? String(data.number) : null,
    account_manager: data.account_manager,
    key_account_manager: data.key_account_manager,
    type: data.type,
    uid: data.uid,
    homepage: data.homepage,
    address: {
      zip: data.address.zip,
      street: data.address.street,
      country: data.address.country,
      city: data.address.city
    },
    contact_since: data.contact_since
  }

  return request({
    url: '/api/v1/contacts/' + data.id,
    method: 'put',
    data: body
  })
}

export function getContactById(id) {
  return request({
    url: '/api/v1/contacts/' + id,
    method: 'get'
  })
}

export function getTasksForContact(data) {
  const params = []
  const listQuery = data.listQuery

  if (listQuery) {
    params.push('page=' + listQuery.page)
    params.push('limit=' + listQuery.limit)
    if (listQuery.order) {
      params.push('order=' + listQuery.order)
    }
  }
  return request({
    url: '/api/v1/contacts/' + data.contact_id + '/tasks?' + params.join('&'),
    method: 'get'
  })
}

export function getProtocolsForContact(data) {
  let params = ''
  const listQuery = data.listQuery

  if (listQuery) {
    params = '?page=' + listQuery.page + '&limit=' + listQuery.limit
  }
  return request({
    url: '/api/v1/contacts/' + data.contact_id + '/protocols' + params,
    method: 'get'
  })
}

export function getContactByNumber(id) {
  return request({
    url: '/api/v1/contacts/attributes/number/' + id,
    method: 'get'
  })
}

/**
 * Delete all null (or undefined) properties from an object.
 * Set 'recurse' to true if you also want to delete properties in nested objects.
 */
function cleanup(test, recurse) {
  for (var i in test) {
    if (test[i] == null || test[i] === '' || test[i] === 0) {
      delete test[i]
    } else if (recurse && typeof test[i] === 'object') {
      cleanup(test[i], recurse)
    }
  }
}
