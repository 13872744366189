import { getUserById } from '@/api/user'
import store from '../index'
import { getUserPermissionsById } from '@/api/user'

const state = {
  permissions: null,
  current_user: null
}

const mutations = {
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_CURRENT_USER: (state, current_user) => {
    state.current_user = current_user
  }
}

const actions = {
  resetState({ commit }) {
    commit('SET_PERMISSIONS', null)
    commit('SET_CURRENT_USER', null)
  },
  async getCurrentUser({ commit }, force = false) {
    return new Promise((resolve, reject) => {
      if (state.current_user && !force) {
        resolve(state.current_user)
      } else {
        getUserById(store.getters.user_id).then(resp => {
          commit('SET_CURRENT_USER', resp.data.data)
          resolve(resp.data.data)
        }).catch(err => {
          reject(err)
        })
      }
    })
  },
  getMyPermissions({ commit }, force = false) {
    if (!force) {
      if (state.permissions) {
        return new Promise(resolve => {
          resolve(state.permissions)
        })
      }
    }
    return new Promise(resolve => {
      getUserPermissionsById(store.getters.user_id).then(resp => {
        const permissions = resp.data
        commit('SET_PERMISSIONS', permissions)
        resolve(permissions)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

