// Icons
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as fa_all from '@fortawesome/free-solid-svg-icons'

library.add(fa_all.faGlobe)
library.add(fa_all.faAngleDoubleUp)
library.add(fa_all.faAngleUp)
library.add(fa_all.faAngleDown)
library.add(fa_all.faAngleDoubleDown)
library.add(fa_all.faAngleLeft)
library.add(fa_all.faAngleRight)
library.add(fa_all.faEye)
library.add(fa_all.faEyeSlash)
library.add(fa_all.faLink)
library.add(fa_all.faUnlink)
library.add(fa_all.faInfoCircle)
library.add(fa_all.faComment)
library.add(fa_all.faTasks)
library.add(fa_all.faHome)
library.add(fa_all.faAddressBook)
library.add(fa_all.faClipboardList)
library.add(fa_all.faSlidersH)
library.add(fa_all.faUserFriends)
library.add(fa_all.faCross)
library.add(fa_all.faUserTie)
library.add(fa_all.faStar)
library.add(fa_all.faPlus)
library.add(fa_all.faMinus)
library.add(fa_all.faEllipsisH)
library.add(fa_all.faCheckSquare)
library.add(fa_all.faPrint)
library.add(fa_all.faPaperPlane)
library.add(fa_all.faParagraph)
library.add(fa_all.faAlignCenter)
library.add(fa_all.faAlignRight)
library.add(fa_all.faAlignLeft)
library.add(fa_all.faAlignJustify)
library.add(fa_all.faGripLines)
library.add(fa_all.faCaretSquareDown)
library.add(fa_all.faDotCircle)
library.add(fa_all.faStarHalfAlt)
library.add(fa_all.faClock)
library.add(fa_all.faCalendarAlt)
library.add(fa_all.faCalendarDay)
library.add(fa_all.faVoteYea)

library.add(fa_all.faBold)
library.add(fa_all.faItalic)
library.add(fa_all.faUnderline)
library.add(fa_all.faStrikethrough)
library.add(fa_all.faHighlighter)
library.add(fa_all.faHeading)
library.add(fa_all.faQuoteRight)
library.add(fa_all.faTable)
library.add(fa_all.faUndo)
library.add(fa_all.faRedo)
library.add(fa_all.faListUl)
library.add(fa_all.faListOl)
library.add(fa_all.faQuestionCircle)
library.add(fa_all.faExclamationCircle)
library.add(fa_all.faMoneyCheck)

import * as far_all from '@fortawesome/free-regular-svg-icons'

library.add(far_all.faStar)
library.add(far_all.faTrashAlt)
library.add(far_all.faCheckSquare)
library.add(far_all.faSquare)
library.add(far_all.faCheckCircle)
library.add(far_all.faTimesCircle)
library.add(far_all.faCircle)
library.add(far_all.faDotCircle)
library.add(far_all.faCreditCard)
library.add(far_all.faQuestionCircle)

import * as fab_all from '@fortawesome/free-brands-svg-icons'
library.add(fab_all.faCcVisa)
