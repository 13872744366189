const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  roles: state => state.permission.roles,
  permission_routes: state => state.permission.routes,
  permissions: state => state.myuser.permissions,
  isAuthenticated: state => state.auth.isAuthenticated,
  accessToken: state => state.auth.accessToken,
  refreshToken: state => state.auth.refreshToken,
  user_id: state => state.auth.user_id,
  user_list: state => state.users.user_list,
  current_user: state => state.myuser.current_user,
  available_accounts: state => state.account.accounts,
  offer_search_filter: state => state.offer.offer_search_filter,
  task_search_filter: state => state.task.task_search_filter,
  survey_search_filter: state => state.survey.survey_search_filter,
  protocol_search_filter: state => state.protocol.protocol_search_filter,
  help: state => state.help.help,
  latest_version: state => state.client.latest_version,
  update_available: state => state.client.update_available,

  logo: state => state.account_settings.nav_logo,
  custom_logo: state => state.account_settings.custom_logo,
  icon: state => state.account_settings.nav_logo_icon,
  powered_by_logo: state => state.account_settings.nav_logo_powered_by
}

export default getters
