import { updateTask, getContactsForTask, setAssigneeStatusForTask, setWatchStatusForTask, unassignUserFromTask, delegateTask, removeDelegation, addAssignee, getProtocolsForTask } from '@/api/task'

const state = {
  task_search_filter: null
}

const mutations = {
  SET_TASK_SEARCH_FILTER: (state, filter) => {
    state.task_search_filter = filter
  }
}

const actions = {
  updateTaskSearchFilter({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit('SET_TASK_SEARCH_FILTER', filters)
      resolve()
    })
  },
  updateTask({ commit }, task) {
    return new Promise((resolve, reject) => {
      updateTask(task).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getContactsForTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      getContactsForTask(data).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getProtocolsForTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      getProtocolsForTask(data).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setAssigneeStatusForTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      setAssigneeStatusForTask(data.id, data.status).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setWatchStatusForTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      setWatchStatusForTask(data.id, data.status).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  unassignUserFromTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      unassignUserFromTask(data.id, data.user_id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  delegateTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      delegateTask(data.id, data.user_id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  removeDelegation({ commit }, data) {
    return new Promise((resolve, reject) => {
      removeDelegation(data.id, data.user_id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  addAssignee({ commit }, data) {
    return new Promise((resolve, reject) => {
      addAssignee(data.id, data.user_id).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

