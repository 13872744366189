var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"badge"},[_vm._t("default"),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden && (_vm.valTop || _vm.valTop === 0 || (_vm.isDot && _vm.dotPosition === 'top'))),expression:"!hidden && (valTop || valTop === 0 || (isDot && dotPosition === 'top'))"}],staticClass:"badge_content",class:[
      _vm.isDot ? 'badge_dot_' + _vm.topType : 'badge_content_' + _vm.topType,
      {
        'is-fixed': _vm.isFixed,
        'dot_top': _vm.isDot,
        'badge_top': !_vm.isDot,
        'is-dot': _vm.isDot,
        'style-square': _vm.square,
        'style-rounded': !_vm.square
      }
    ],domProps:{"textContent":_vm._s(_vm.valTop)}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden && (_vm.valBot || _vm.valBot === 0 || (_vm.isDot && _vm.dotPosition === 'bottom'))),expression:"!hidden && (valBot || valBot === 0 || (isDot && dotPosition === 'bottom'))"}],staticClass:"badge_content",class:[
      _vm.isDot ? 'badge_dot_' + _vm.bottomType : 'badge_content_' + _vm.bottomType,
      {
        'is-fixed': _vm.isFixed,
        'dot_bottom': _vm.isDot,
        'badge_bottom': !_vm.isDot,
        'is-dot': _vm.isDot,
        'style-square': _vm.square,
        'style-rounded': !_vm.square
      }
    ],domProps:{"textContent":_vm._s(_vm.valBot)}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }