import request from '@/utils/request'
import {
  ASSIGNEE_FILTER_ATTRIBUTE, AUTHOR_FILTER_ATTRIBUTE, DUE_DATE_FILTER_ATTRIBUTE, PUBLISHED_FILTER_ATTRIBUTE,
  SEARCH_FILTER_ATTRIBUTE, STATUS_FILTER_ATTRIBUTE,
  TEAM_FILTER_ATTRIBUTE, USER_STATUS_FILTER_ATTRIBUTE, WITH_RESOLVED_FILTER_ATTRIBUTE
} from '../config/search_filter/task_filter'

export function getAllTasks(data = null) {
  const filterAttributes = data.filterAttributes
  const listQuery = data.listQuery

  const params = []
  if (listQuery) {
    if (listQuery.page) {
      params.push('page=' + listQuery.page)
    }
    if (listQuery.limit) {
      params.push('limit=' + listQuery.limit)
    }
    if (listQuery.order) {
      params.push('order=' + listQuery.order)
    }
  }
  if (filterAttributes) {
    if (filterAttributes.due_date && filterAttributes.due_date.length) {
      params.push(DUE_DATE_FILTER_ATTRIBUTE + '=' + filterAttributes.due_date.join(','))
    }
    if (filterAttributes.author && filterAttributes.author.length) {
      params.push(AUTHOR_FILTER_ATTRIBUTE + '=' + filterAttributes.author.join(','))
    }
    if (filterAttributes.published && filterAttributes.published.length) {
      params.push(PUBLISHED_FILTER_ATTRIBUTE + '=' + filterAttributes.published.join(','))
    }
    if (filterAttributes.status) {
      params.push(STATUS_FILTER_ATTRIBUTE + '=' + filterAttributes.status)
    }
    if (filterAttributes.assignees && filterAttributes.assignees.length) {
      params.push(ASSIGNEE_FILTER_ATTRIBUTE + '=' + filterAttributes.assignees.join(','))
    }
    if (filterAttributes.teams && filterAttributes.teams.length) {
      params.push(TEAM_FILTER_ATTRIBUTE + '=' + filterAttributes.teams.join(','))
    }
    if (filterAttributes.search) {
      params.push(SEARCH_FILTER_ATTRIBUTE + '=' + filterAttributes.search)
    }
    if (filterAttributes.user_status && filterAttributes.user_status.length) {
      params.push(USER_STATUS_FILTER_ATTRIBUTE + '=' + filterAttributes.user_status)
    }
    if (filterAttributes.hide_resolved !== null &&
      (!filterAttributes.status || (filterAttributes.user_status && filterAttributes.user_status.length))) {
      params.push(WITH_RESOLVED_FILTER_ATTRIBUTE + '=' + filterAttributes.hide_resolved)
    }
  }
  return request({
    url: '/api/v1/tasks?' + params.join('&'),
    method: 'get'
  })
}

export function printTasks(data = null) {
  const filterAttributes = data.filterAttributes
  const listQuery = data.listQuery

  const params = []
  if (listQuery) {
    params.push('page=' + listQuery.page)
    params.push('limit=' + listQuery.limit)
  }
  if (filterAttributes) {
    if (filterAttributes.due_date && filterAttributes.due_date.length) {
      params.push(DUE_DATE_FILTER_ATTRIBUTE + '=' + filterAttributes.due_date.join(','))
    }
    if (filterAttributes.author && filterAttributes.author.length) {
      params.push(AUTHOR_FILTER_ATTRIBUTE + '=' + filterAttributes.author.join(','))
    }
    if (filterAttributes.published && filterAttributes.published.length) {
      params.push(PUBLISHED_FILTER_ATTRIBUTE + '=' + filterAttributes.published.join(','))
    }
    if (filterAttributes.status) {
      params.push(STATUS_FILTER_ATTRIBUTE + '=' + filterAttributes.status)
    }
    if (filterAttributes.assignees && filterAttributes.assignees.length) {
      params.push(ASSIGNEE_FILTER_ATTRIBUTE + '=' + filterAttributes.assignees.join(','))
    }
    if (filterAttributes.teams && filterAttributes.teams.length) {
      params.push(TEAM_FILTER_ATTRIBUTE + '=' + filterAttributes.teams.join(','))
    }
    if (filterAttributes.search) {
      params.push(SEARCH_FILTER_ATTRIBUTE + '=' + filterAttributes.search)
    }
    if (filterAttributes.user_status && filterAttributes.user_status.length) {
      params.push(USER_STATUS_FILTER_ATTRIBUTE + '=' + filterAttributes.user_status)
    }
    if (filterAttributes.hide_resolved !== null &&
      (!filterAttributes.status || (filterAttributes.user_status && filterAttributes.user_status.length))) {
      params.push(WITH_RESOLVED_FILTER_ATTRIBUTE + '=' + filterAttributes.hide_resolved)
    }
  }
  return request({
    url: '/api/v1/tasks/print?' + params.join('&'),
    method: 'get',
    responseType: 'blob'
  })
}

export function linkContactToTask(task_id, contact_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/contacts',
    method: 'post',
    data: { contact_id: contact_id }
  })
}

export function removeContactToTaskLink(task_id, contact_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/contacts/' + contact_id,
    method: 'delete'
  })
}

export function changeContactPublic(task_id, contact_id, status) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/contacts/' + contact_id,
    method: 'put',
    data: { public: status }
  })
}

export function searchTaskByString(search = '') {
  return request({
    url: '/api/v1/tasks?search=' + search,
    method: 'get'
  })
}

export function uploadFile(task_id, file) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/files',
    method: 'post',
    data: { file: file }
  })
}

export function getFilesForTask(task_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/files',
    method: 'get'
  })
}

export function deleteFileForTask(task_id, file_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/files/' + file_id,
    method: 'delete'
  })
}

export function linkProtocolToTask(task_id, protocol_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/protocols',
    method: 'post',
    data: { protocol_id: protocol_id }
  })
}

export function removeProtocolToTaskLink(task_id, protocol_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/protocols/' + protocol_id,
    method: 'delete'
  })
}

export function createTask(formData) {
  const data = {
    id: formData.id,
    published: formData.published,
    author_user_id: formData.author_user_id,
    assignees: formData.assignees,
    viewers: formData.viewers,
    due_date: formData.due_date,
    title: formData.title,
    body: formData.body,
    type: formData.type,
    team_id: formData.team_id,
    claims: formData.claims,
    priority: formData.priority,
    comments_enabled: formData.comments_enabled,
    linked_contacts: formData.linked_contacts,
    linked_protocols: formData.linked_protocols
  }
  cleanup(data)
  return request({
    url: '/api/v1/tasks/',
    method: 'post',
    data: data
  })
}

export function createEmptyTask(id) {
  return request({
    url: '/api/v1/tasks/empty',
    method: 'post',
    data: { id: id }
  })
}

export function getTaskById(id) {
  return request({
    url: '/api/v1/tasks/' + id,
    method: 'get'
  })
}

export function printTaskById(id) {
  return request({
    url: '/api/v1/tasks/' + id + '/print',
    method: 'get',
    responseType: 'blob'
  })
}

export function deleteTask(id) {
  return request({
    url: '/api/v1/tasks/' + id,
    method: 'delete'
  })
}

export function getContactsForTask(id) {
  return request({
    url: '/api/v1/tasks/' + id + '/contacts',
    method: 'get'
  })
}

export function getProtocolsForTask(id) {
  return request({
    url: '/api/v1/tasks/' + id + '/protocols',
    method: 'get'
  })
}

export function setAssigneeStatusForTask(id, status) {
  return request({
    url: '/api/v1/tasks/' + id + '/status',
    method: 'patch',
    data: { status: status }
  })
}

export function updateTask(task) {
  return request({
    url: '/api/v1/tasks/' + task.id,
    method: 'patch',
    data: task
  })
}

export function setWatchStatusForTask(id, status) {
  return request({
    url: '/api/v1/tasks/' + id + '/watch',
    method: 'patch',
    data: { status: status }
  })
}

export function unassignUserFromTask(id, user_id) {
  return request({
    url: '/api/v1/tasks/' + id + '/assignees/' + user_id,
    method: 'delete'
  })
}

export function createComment(task_id, body) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/comments',
    method: 'post',
    data: { body: body }
  })
}

export function updateComment(task_id, comment_id, body) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/comments/' + comment_id,
    method: 'PUT',
    data: { body: body }
  })
}

export function deleteComment(task_id, comment_id) {
  return request({
    url: '/api/v1/tasks/' + task_id + '/comments/' + comment_id,
    method: 'delete'
  })
}

export function addAssignee(id, user_id) {
  return request({
    url: '/api/v1/tasks/' + id + '/assignees',
    method: 'post',
    data: { user_id: user_id }
  })
}

export function addViewer(id, user_id) {
  return request({
    url: '/api/v1/tasks/' + id + '/viewers',
    method: 'post',
    data: { user_id: user_id }
  })
}

export function removeViewer(id, user_id) {
  return request({
    url: '/api/v1/tasks/' + id + '/viewers/' + user_id,
    method: 'delete'
  })
}

/**
 * Delete all null (or undefined) properties from an object.
 * Set 'recurse' to true if you also want to delete properties in nested objects.
 */
function cleanup(test, recurse) {
  for (var i in test) {
    if (test[i] == null || test[i] === '') {
      delete test[i]
    } else if (recurse && typeof test[i] === 'object') {
      cleanup(test[i], recurse)
    }
  }
}

