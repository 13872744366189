import EventBus from '@/eventbus'
import { generateRoutes } from '../routes'
import store from '@/store'
import router from '@/router'

function account_interceptor(instance) {
  instance.interceptors.response.use(function(response) {
    return response
  }, function(error) {
    const { config, response } = error
    const originalRequest = config

    let isAlreadyFetchingAccountSignature = false
    let subscribers = []

    function onAccountSignatureFetched(signature) {
      subscribers = subscribers.filter(callback => callback(signature))
    }

    function addSubscriber(callback) {
      subscribers.push(callback)
    }

    if (response && response.status === 403 &&
      (error.response.data.message === 'Invalid Account sent' ||
        error.response.data.message === 'Account Signature Invalid' ||
        error.response.data.message === 'Account Signature Outdated' ||
        error.response.data.message === 'Account missing')) {
      if (!isAlreadyFetchingAccountSignature) {
        isAlreadyFetchingAccountSignature = true
        EventBus.$emit('loading-token', true)
        store.dispatch('account/getCurrentAccount', true).then(account => {
          generateRoutes(account.data).catch(() => {}) // catching error here, as it's just telling us, the routes have already been generated before
          onAccountSignatureFetched(account.signature)
        }).catch(() => {
          router.push('/accounts')
        }).finally(() => {
          EventBus.$emit('loading-token', false)
          isAlreadyFetchingAccountSignature = false
        })
      }

      return new Promise((resolve) => {
        addSubscriber(signature => {
          originalRequest.headers.Account = signature
          resolve(instance(originalRequest))
        })
      })
    }
    return Promise.reject(error)
  })
}

export default account_interceptor
