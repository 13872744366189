import request from '@/utils/request'

export function login(email, password, account_id) {
  const data = {
    username: email,
    password: password,
    account_id: account_id
  }
  return request({
    url: '/api/v1/oauth/token',
    method: 'post',
    data: data
  })
}

export function respond_to_auth_challenge(challenge_name, client_id, session, password, username) {
  const data = {
    challenge_name: challenge_name,
    client_id: client_id,
    session: session,
    password: password,
    username: username
  }
  return request({
    url: '/api/v1/oauth/challenge',
    method: 'post',
    data: data
  })
}

export function refresh(refresh_token, username, client_id) {
  const data = {
    refresh_token: refresh_token,
    username: username,
    client_id: client_id
  }
  return request({
    url: '/api/v1/oauth/refresh',
    method: 'post',
    timeout: 10000, // request timeout just for token refresh,
    data: data
  })
}
