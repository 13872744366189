import constantRoutes from '../router/modules/constantRoutes'
import store from '@/store'
import router from '@/router'

export async function generateRoutes(account) {
  if (!account) {
    router.push('/home')
  }
  return new Promise((resolve, reject) => {
    // check if user has userinfos stored locally
    const hasRoles = store.getters.roles && store.getters.roles.length > 0
    const hasRoutes = store.getters.permission_routes.length > constantRoutes.length

    if (hasRoles && hasRoutes) {
      reject()
    } else {
      const data = {
        roles: account.roles,
        cff: account.cff
      }
      store.dispatch('permission/generateRoutes', data).then(routes => {
        // dynamically add accessible routes
        resolve(routes)
      })
      // router.addRoutes(routes)
      // hack method to ensure that addRoutes is complete
      // set the replace: true, so the navigation will not leave a history record
      // resolve(false)
    }
  })
}

export function open(url, event) {
  if (event && (event.metaKey || event.ctrlKey)) {
    openPageInNewTab(url)
  } else {
    openPage(url)
  }
}

export function openPage(url) {
  router.push(url)
}

export function openPageWithRefresh(url) {
  router.go(url)
}

export function openPageInNewTab(url) {
  window.open(url, '_blank')
}
