<template>
  <div style="padding: 0;" @click="toggleClick">
    <fa-icon v-if="sidebar.opened" icon="angle-left" size="lg" class="pointer hamburger-icon" :class="[position === 'sidebar' ? 'sidebar-icon' : 'navbar-icon']" />
    <fa-icon v-if="!sidebar.opened" icon="angle-right" size="lg" class="pointer hamburger-icon" :class="[position === 'sidebar' ? 'sidebar-icon' : 'navbar-icon']" />
  </div>
</template>

<script>
import EventBus from '../../eventbus'
import { mapGetters } from 'vuex'

export default {
  name: 'Hamburger',
  props: {
    position: {
      type: String,
      default: 'sidebar'
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ])
  },
  methods: {
    toggleClick() {
      this.$store.dispatch('app/toggleSideBar')
      EventBus.$emit('toggleSideBar', this.sidebar.opened)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.hamburger-icon {
  padding-top: 3px;
}

.sidebar-icon {
  color: white;
  font-size: 30px;
}

.navbar-icon {
  color: lightgrey;
  margin-top: 8px;
  margin-left: 18px;
  font-size: 30px;
}
</style>
