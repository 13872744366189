import request from '@/utils/request'
import store from '@/store'

export async function getAccounts() {
  const url = '/api/v1/users/' + store.getters.user_id + '/accounts'
  return request({
    url: url,
    method: 'get'
  })
}

export async function checkIfDomainExists(domain) {
  const url = '/api/v1/account_name/' + domain
  return request({
    url: url,
    method: 'get'
  })
}

export async function getAccountInfos() {
  const account = await store.dispatch('account/getCurrentAccount')
  return request({
    url: '/api/v1/accounts/' + account.data.account_id,
    method: 'get'
  })
}

export function getMembers(list_query, filter, search, unscoped = true) {
  const query_params = []
  if (list_query && list_query.page) {
    query_params.push('page=' + list_query.page)
  }
  if (list_query && list_query.limit) {
    query_params.push('limit=' + list_query.limit)
  }
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      query_params.push('filter[' + key + ']=' + value)
    }
  }
  query_params.push('unscoped=' + unscoped)

  if (search) {
    query_params.push('search=' + search)
  }

  return request({
    url: '/api/v1/accounts/members?' + query_params.join('&'),
    method: 'get'
  })
}

export async function changeMemberStatus(user_id, status) {
  return request({
    url: '/api/v1/accounts/members/' + user_id + '/attributes/status',
    data: {
      'status': status
    },
    method: 'patch'
  })
}
