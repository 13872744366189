
const state = {
  latest_version: '',
  update_available: false
}

const mutations = {
  SET_LATEST_VERSION: (state, version) => {
    state.latest_version = version
    state.update_available = process.env.VUE_APP_PIPELINE_ID && version && version.toString() !== process.env.VUE_APP_PIPELINE_ID.toString()
  }
}

const actions = {
  setLatestVersion({ commit }, version) {
    commit('SET_LATEST_VERSION', version)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
