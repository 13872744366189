// import parseTime, formatTime and set to filter
import moment from 'moment'
import i18n from '@/lang'

export { parseTime, formatTime } from '@/utils'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export function decimalComma(string) {
  return string.replace(/,/g, '.')
}

export function date(value) {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
}

export function timestampToDateTime(value) {
  if (value) {
    const date = moment.unix(value).format('DD.MM.YYYY HH:mm')
    if (date === 'Invalid date') {
      return 'No date available'
    } else {
      return date
    }
  }
}

export function msTimestampToDateTime(value) {
  if (value) {
    const date = moment(value).format('DD.MM.YYYY HH:mm')
    if (date === 'Invalid date') {
      return 'No date available'
    } else {
      return date
    }
  }
}

export function timestampToDate(value) {
  if (value) {
    return moment.unix(value).format('DD.MM.YYYY')
  }
}

export function msTimestampToDate(value) {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
}

export function dateTimePicker(value) {
  if (value) {
    return moment(value).format('DD.MM.YYYY HH:mm')
  }
}

export function yesNo(value) {
  if (value) {
    return i18n.t('booleans.yes')
  }
  return i18n.t('booleans.no')
}

export function gender(value) {
  if (value === 'male') {
    return i18n.t('models.user.attributes.gender.options.male')
  } else if (value === 'female') {
    return i18n.t('models.user.attributes.gender.options.female')
  }
  return value
}

export function statusFilter(status) {
  const statusMap = {
    published: 'success',
    draft: 'info'
  }
  return statusMap[status]
}

export function prettyJson(value) {
  return JSON.stringify(value, null, 2)
}

export function sort(array) {
  return array.sort()
}
