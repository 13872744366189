import store from '@/store'
import FeatureFlags from '../../../enum/FeatureFlags'

const hasProtocolsApp = {
  async inserted(el, binding, vnode) {
    let applications = []
    if (store.getters.user_id) {
      const account = await store.dispatch('account/getCurrentAccount')
      if (account) {
        applications = account.data.cff
      }
    }

    if (!applications.includes(FeatureFlags.PROTOCOLS_APP)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export default hasProtocolsApp
