export const DOMAIN_ATTR = 'ld' // last logged in domain

export function getLastUsedDomain() {
  let domain_data
  try {
    domain_data = JSON.parse(localStorage.getItem(DOMAIN_ATTR))
    // eslint-disable-next-line no-empty
  } catch (e) {}

  if (domain_data) {
    return domain_data[DOMAIN_ATTR]
  }
  return null
}

export function getStoredDomains() {
  if (localStorage.getItem(DOMAIN_ATTR)) {
    try {
      const domains = JSON.parse(localStorage.getItem(DOMAIN_ATTR))
      if (domains.domains && domains.domains.length) {
        return domains
      }
    } catch (e) {
      return {
        domains: []
      }
    }
  }
  return {
    domains: []
  }
}

export function addDomainToStoredDomains(domain, account_name, username) {
  let domain_data
  try {
    domain_data = JSON.parse(localStorage.getItem(DOMAIN_ATTR))
    // eslint-disable-next-line no-empty
  } catch (e) {}

  let domains = []
  if (domain_data) {
    domains = domain_data.domains
  } else {
    domain_data = {}
  }

  if (domains && Array.isArray(domains)) {
    if (!domains.find(d => d.domain === domain)) {
      domains.push({
        domain: domain,
        account_name: account_name,
        username: username
      })
    } else {
      domains.forEach(d => {
        if (d.domain === domain) {
          d.username = username
        }
      })
    }
  } else {
    domains.push({
      domain: domain,
      account_name: account_name,
      username: username
    })
  }

  domain_data.domains = domains
  domain_data[DOMAIN_ATTR] = domain
  localStorage.setItem(DOMAIN_ATTR, JSON.stringify(domain_data))
}

export function removeDomainToStoredDomains(domain) {
  let domain_data
  try {
    domain_data = JSON.parse(localStorage.getItem(DOMAIN_ATTR))
    // eslint-disable-next-line no-empty
  } catch (e) {}

  let domains
  if (domain_data) {
    domains = domain_data.domains
  } else {
    domain_data = {}
  }

  const new_domains = []
  if (domains && Array.isArray(domains)) {
    domains.forEach(d => {
      if (domain !== d.domain && domain !== d.account_name) {
        new_domains.push(d)
      }
    })
  }

  domain_data.domains = new_domains
  if (domain_data[DOMAIN_ATTR] === domain) {
    if (new_domains.length > 0) {
      domain_data[DOMAIN_ATTR] = new_domains[0].domain
    }
  }
  localStorage.setItem(DOMAIN_ATTR, JSON.stringify(domain_data))
}
