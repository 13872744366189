// translate router.meta.title, be used in breadcrumb sidebar tagsview

export function generateTitle(title) {
  const hasKey = this.$te('routes.' + title)
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    return this.$t('routes.' + title)
  }
  return title
}
