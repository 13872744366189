import store from '@/store'

function client_version_interceptor(instance) {
  instance.interceptors.response.use(function(response) {
    const env = process.env.VUE_APP_ENV
    const beta_version = response.headers['x-latest-beta-client-version']
    const prd_version = response.headers['x-latest-client-version']
    if (env === 'beta' && beta_version) {
      store.dispatch('client/setLatestVersion', beta_version)
    } else {
      store.dispatch('client/setLatestVersion', prd_version)
    }
    return response
  }, function(error) {
    return Promise.reject(error)
  })
}

export default client_version_interceptor
