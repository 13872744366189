
import { Component, Vue } from 'vue-property-decorator'
import { open } from '@/utils/routes'
import FeatureFlags from '@/enum/FeatureFlags'
@Component
export default class AddButton extends Vue {
  created() {
    this.checkPermissions()
  }

  commands(event:string) {
    switch (event) {
      case 'createTask':
        this.createTask()
        break
      case 'createProtocol':
        this.createProtocol()
        break
      case 'createOffer':
        this.createOffer()
        break
      case 'createSurvey':
        this.createSurvey()
        break
    }
  }

  public hasFeatureFlag: any
  private canSeeProtocolsButton:boolean = false
  private canSeeOffersButton:boolean = false
  private canSeeSurveysButton:boolean = false

  async checkPermissions() {
    const roles = this.$store.getters && this.$store.getters.roles
    const protocols_ff = await this.hasFeatureFlag(FeatureFlags.PROTOCOLS_APP)
    const offers_ff = await this.hasFeatureFlag(FeatureFlags.OFFERS_APP)
    const surveys_ff = await this.hasFeatureFlag(FeatureFlags.SURVEYS_APP)

    this.canSeeOffersButton = roles.includes('user') && offers_ff
    this.canSeeProtocolsButton = roles.includes('user') && protocols_ff
    this.canSeeSurveysButton = roles.includes('surveys') && surveys_ff
  }

  createTask() {
    open('/tasks/new')
  }

  createProtocol() {
    open('/protocols/new')
  }

  createSurvey() {
    open('/surveys/new')
  }

  createOffer() {
    open('/offers/new')
  }
}
