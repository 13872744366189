<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <router-link class="sidebar-logo-link" to="/">
      <img v-show="collapse" :src="$store.getters.icon" alt="Logo-square" class="sidebar-logo logo-square" style="height: 50px; width: auto">
      <img v-show="!collapse" :src="$store.getters.logo" alt="Logo-full" class="sidebar-logo logo-full" style="height: 50px; width: auto">
      <h1 class="sidebar-title" />
    </router-link>
  </div>
</template>

<script>
import { getAccountLogo } from '../../../api/account'
import { getPersonalizationData } from '../../../api/v2/settings'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: $--color-primary-menu;
  text-align: left;
  overflow: hidden;

  .logo-full {
  }

  .logo-square {
  }

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      // margin-right: 12px;
      position: relative;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
