// fix el-select
const fixElSelect = function() {
  document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach((v) => {
    const elInput = document.querySelector('.el-select[data-is-search="true"]:hover input[readonly]')
    if (elInput) {
      elInput.readOnly = false
      elInput.blur()
      elInput.focus()
    }
  })
}
document.addEventListener('focusin', fixElSelect)
document.addEventListener('click', fixElSelect)
document.addEventListener('touchstart', fixElSelect)
