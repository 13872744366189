import isUser from './is/is_user'
import isContact from './is/is_contact'
import isAdmin from './is/is_admin'
import isManager from './is/is_manager'
import isNotManager from './is/is_not_manager'
import isNotUser from './is/is_not_user'
import isNotContact from './is/is_not_contact'
import isTeamAdmin from './is/is_team_admin'

const install = function(Vue) {
  Vue.directive('if-is-user', isUser)
  Vue.directive('if-is-contact', isContact)
  Vue.directive('if-is-admin', isAdmin)
  Vue.directive('if-is-manager', isManager)
  Vue.directive('if-is-not-manager', isNotManager)
  Vue.directive('if-is-not-contact', isNotContact)
  Vue.directive('if-is-not-user', isNotUser)
  Vue.directive('if-is-team_admin', isTeamAdmin)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

isUser.install = install
export default isUser
