import request from '@/utils/request'

export function listUpdates(limit) {
  return request({
    url: '/api/v2/updates?limit=' + limit,
    method: 'get'
  })
}

export function listUpdatesForAdmin(limit) {
  return request({
    url: '/api/v2/admin/updates?limit=' + limit,
    method: 'get'
  })
}

export function createUpdate(update) {
  return request({
    url: '/api/v2/admin/updates',
    method: 'post',
    data: update
  })
}

export function updateUpdate(update) {
  return request({
    url: '/api/v2/admin/updates/' + update.id,
    method: 'put',
    data: update
  })
}
