import store from '@/store'

export default {
  methods: {
    hasFeatureFlag: async function(flag:string) {
      const account = await store.dispatch('account/getCurrentAccount')
      const applications = account.data.cff
      return applications.includes(flag)
    }
  }
}
