<template>
  <div class="navbar">
    <hamburger v-if="device ==='mobile'" id="hamburger-container-navbar" class="hamburger-container-navbar" position="navbar" />
    <div class="right-menu">
      <add-button />
      <bookmarks-menu class="right-menu-item" />
      <help-dropdown-menu class="right-menu-item" />
      <profile-dropdown-menu class="right-menu-item" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Hamburger from '../../components/Hamburger/index'
import ProfileDropdownMenu from './ProfileDropdownMenu'
import HelpDropdownMenu from './HelpDropdownMenu'
import BookmarksMenu from './BookmarksMenu'
import AddButton from './AddButton'

export default {
  components: {
    AddButton,
    BookmarksMenu,
    HelpDropdownMenu,
    ProfileDropdownMenu,
    Hamburger
  },
  data() {
    return {
      nrOfAddButtons: 0
    }
  },
  computed: {
    ...mapGetters([
      'device'
    ])
  },
  methods: {
    isAdmin() {
      let admin = false
      this.account.roles.forEach(role => {
        if (role === 'admin') {
          admin = true
        }
      })
      return admin
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container-navbar {
    line-height: 46px;
    height: 100%;
    width: 50px;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;
  }

  .right-menu {
    float: right;
    height: 100%;
    margin-right: 20px;
    line-height: 50px;

    .right-menu-item {
      display: inline-block;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;
    }
  }
}
</style>
