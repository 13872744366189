import { getUsers, getUserById } from '../../api/v2/users'

const state = {
  user_list: []
}

const mutations = {
  SET_USER_LIST: (state, user_list) => {
    state.user_list = user_list
  }
}

const actions = {
  resetState({ commit }) {
    commit('SET_USER_LIST', [])
  },
  getUserList({ commit }) {
    return new Promise((resolve, reject) => {
      getUsers(null, null, null, false).then(resp => { // unscoped
        commit('SET_USER_LIST', resp.data.data)
        resolve(resp.data.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject()
      }
      const given_user = state.user_list.find(u => u.id === id)
      if (given_user) {
        resolve(given_user)
      } else {
        getUserById(id).then(resp => {
          let user_list = []
          if (state.user_list.length > 0) {
            user_list = state.user_list
          }
          user_list.push(resp.data.data)
          commit('SET_USER_LIST', user_list)
          resolve(resp.data.data)
        }).catch(err => {
          reject(err)
        })
      }
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

