import request from '@/utils/request'

export function getMyBookmarks() {
  return request({
    url: '/api/v1/bookmarks/',
    method: 'get'
  })
}

export function createNewBookmark(path, label) {
  return request({
    url: '/api/v1/bookmarks/',
    method: 'post',
    data: {
      path: path,
      label: label
    }
  })
}

export function deleteBookmark(id) {
  return request({
    url: '/api/v1/bookmarks/' + id,
    method: 'delete'
  })
}

export function updateBookmark(id, payload) {
  return request({
    url: '/api/v1/bookmarks/',
    method: 'patch',
    data: payload
  })
}
