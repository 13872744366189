import EventBus from '@/eventbus'
import store from '@/store'
import router from '@/router'
import { noAuthList } from '../request'

let isAlreadyFetchingAccessToken = false
let subscribers = []

function access_token_interceptor(instance) {
  instance.interceptors.response.use(function(response) {
    return response
  }, function(error) {
    const { config, response } = error
    if (!config) {
      return Promise.reject(error)
    }
    const base_url = process.env['VUE_APP_BASE_API']
    const path = config.url.replace(base_url, '')
    if (noAuthList.find(url => path.match(url))) {
      // if this request should not refresh 401s e.g. like logins
      return Promise.reject(error)
    }
    const originalRequest = config

    function onAccessTokenFetched(accessToken) {
      subscribers = subscribers.filter(callback => callback(accessToken))
    }

    function addSubscriber(callback) {
      subscribers.push(callback)
    }

    if (response && response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        EventBus.$emit('loading-token', true)
        store.dispatch('auth/refresh').then(token => {
          onAccessTokenFetched(token.AccessToken)
        }).catch(() => {
          store.dispatch('auth/logout').then(() => {
            let goToUrl
            if (router.history.pending) {
              goToUrl = router.history.pending.fullPath
            }
            if (goToUrl) {
              router.push('/login?target=' + encodeURI(goToUrl))
            } else {
              router.push('/login')
            }
          })
        }).finally(() => {
          EventBus.$emit('loading-token', false)
          isAlreadyFetchingAccessToken = false
        })
      }
      return new Promise((resolve) => {
        addSubscriber(access_token => {
          originalRequest.headers.Authorization = 'Bearer ' + access_token
          resolve(instance(originalRequest))
        })
      })
    } else {
      return Promise.reject(error)
    }
  })
}

export default access_token_interceptor
