import request from '@/utils/request'

export function getCountries() {
  return request({
    url: '/api/v1/static_content/countries',
    method: 'get'
  })
}

export function getSecurityQuestions() {
  return request({
    url: '/api/v1/static_content/security_questions',
    method: 'get'
  })
}
