import hasSurveysApp from './has-surveys-app'

const install = function(Vue) {
  Vue.directive('has-surveys-app', hasSurveysApp)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

hasSurveysApp.install = install
export default hasSurveysApp
