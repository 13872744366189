/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

const constantRoutes = [
  {
    path: '/link',
    component: Layout,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'noauth',
        component: () => import('@/views/redirect/index'),
        meta: {
          requiresAuth: false,
          noCache: true
        }
      }
    ]
  },
  {
    path: '/acc',
    component: Layout,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: ':account_id',
        component: () => import('@/views/redirect/index'),
        meta: {
          requiresAuth: false,
          noCache: true
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/auth'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: ':domain',
        component: () => import('@/views/login/auth'),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: '/activate',
    component: () => import('@/views/login/auth'),
    meta: {
      requiresAuth: false,
      title: 'activate',
      noCache: true
    }
  },
  {
    path: '/register',
    component: () => import('@/views/login/auth'),
    meta: {
      requiresAuth: false,
      title: 'activate',
      noCache: true
    }
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/login/auth'),
    meta: {
      requiresAuth: false,
      title: 'forgot password',
      noCache: true
    }
  },
  {
    path: '/reset-password',
    component: () => import('@/views/login/auth'),
    meta: {
      requiresAuth: false,
      title: 'reset password',
      noCache: true
    }
  },
  {
    path: '/logout',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/login/logout')
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    hidden: true,
    meta: {
      requiresAuth: true
    },
    redirect: '/home'
  },
  {
    path: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index'),
        meta: {
          title: 'home',
          icon: 'home',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/accounts',
    component: () => import('@/views/accounts'),
    hidden: true,
    meta: {
      requiresAuth: true,
      noCache: true
    }
  },
  {
    path: '/profile',
    component: Layout,
    hidden: true,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        component: () => import('@/views/profile/index'),
        meta: { title: 'profile', icon: 'user', noCache: true }
      }
    ]
  }
]

export default constantRoutes
