import { getPersonalizationData } from '../../api/v2/settings'

const state = {
  custom_logo: false,
  nav_logo: 'https://static.centrics.app/navbar/centrics-app-logo-horizontal_white_190x50.png',
  nav_logo_icon: 'https://static.centrics.app/navbar/centrics-app-logo-square_white_54x50.png',
  nav_logo_powered_by: 'https://static.centrics.app/navbar/centrics-app_nav-powered-by_white_190x53.png'
}

const mutations = {
  SET_LOGO: (state, logo) => {
    state.nav_logo = logo
    state.custom_logo = true
  },
  SET_ICON: (state, logo) => {
    state.nav_logo_icon = logo
  }
}

const actions = {
  getAccountSettings({ commit }) {
    return new Promise((resolve, reject) => {
      getPersonalizationData().then(resp => {
        if (resp.data.logo) {
          commit('SET_LOGO', resp.data.logo)
        }
        if (resp.data.icon) {
          commit('SET_ICON', resp.data.icon)
        }
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

