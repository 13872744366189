import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementDeLocale from 'element-ui/lib/locale/lang/de' // element-ui lang
import enLocale from './en-US.json'
import deLocale from './de-DE.json'
import store from '@/store'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  de: {
    ...deLocale,
    ...elementDeLocale
  }
}
export function getLanguage() {
  try {
    if (store && store.getters && store.getters.current_user) {
      const user_info = store.getters.current_user
      setLang(user_info.locale)
      return user_info.locale
    }
    // eslint-disable-next-line no-empty
  } catch (_) {}
  let lang = Cookies.get('language')
  if (!lang) {
    lang = 'de'
  }
  try {
    setLang(lang)
    // eslint-disable-next-line no-empty
  } catch (_) {}
  return lang
}

function setLang(lang) {
  i18n.locale = lang
  Cookies.set('language', lang, { expires: 365, sameSite: 'strict', secure: true })
}

export function getLanguageIso() {
  switch (getLanguage()) {
    case 'en':
      return 'en_US'
    default:
      return 'de_DE'
  }
}

const i18n = new VueI18n({
  // set locale
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
