import asyncRoutes from '@/router/modules/asyncRoutes'
import constantRoutes from '@/router/modules/constantRoutes'

/**
 * Use meta.role to determine if the current user has permission
 * @param data
 * @param route
 */
function hasPermission(data, route) {
  let hasAppApproval = true
  if (route.meta && route.meta.cff) {
    hasAppApproval = data.cff.some(app => route.meta.cff.includes(app))
  }

  if (route.meta && route.meta.roles) {
    const hasRoleApproval = data.roles.some(role => route.meta.roles.includes(role))
    return hasAppApproval && hasRoleApproval
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param data
 */
export function filterAsyncRoutes(routes, data) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(data, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, data)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: [],
  roles: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  resetState({ commit }) {
    commit('SET_ROUTES', [])
    commit('SET_ROLES', [])
  },
  generateRoutes({ commit }, data) {
    return new Promise(resolve => {
      if (!data.cff) {
        data.cff = [] // temporary fix, to migrate users to feature flags
      }
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, data)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  setRoles({ commit }, roles) {
    return new Promise(resolve => {
      commit('SET_ROLES', roles)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
