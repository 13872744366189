/* eslint-disable no-unused-vars */
export enum TeamPermissions {
  PROTOCOLS_READ = 'team.protocols.read',
  PROTOCOLS_EDIT = 'team.protocols.edit',
  PROTOCOLS_DELETE = 'team.protocols.delete',
  TASKS_READ = 'team.tasks.read',
  TASKS_EDIT = 'team.tasks.edit',
  TASKS_DELETE = 'team.tasks.delete',
  ADMIN = 'team.admin'
}

export default TeamPermissions
