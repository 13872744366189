import { login, respond_to_auth_challenge, refresh } from '@/api/auth'
import VueJwtDecode from 'vue-jwt-decode'
import { clearStorage } from '@/utils/storage'
import { resetRouter } from '@/router'
import { addDomainToStoredDomains } from '@/utils/auth'
import store from '../index'

export const ID_TOKEN_ATTR = 'pd'
export const REFRESH_TOKEN_ATTR = 'pk'
export const USER_ID_ATTR = 'pc'
export const CLIENT_ID_ATTR = 'pci'

const state = {
  isAuthenticated: localStorage.getItem(REFRESH_TOKEN_ATTR),
  accessToken: null,
  idToken: localStorage.getItem(ID_TOKEN_ATTR),
  refreshToken: localStorage.getItem(REFRESH_TOKEN_ATTR),
  user_id: localStorage.getItem(USER_ID_ATTR),
  client_id: localStorage.getItem(CLIENT_ID_ATTR)
}

const mutations = {
  SET_ACCESS_TOKEN: (state, data) => {
    state.accessToken = data
  },
  SET_REFRESH_TOKEN: (state, data) => {
    localStorage.setItem(REFRESH_TOKEN_ATTR, data)
    state.refreshToken = data
  },
  SET_ID_TOKEN: (state, data) => {
    localStorage.setItem(ID_TOKEN_ATTR, data)
    state.idToken = data
  },
  SET_USER_ID: (state, data) => {
    localStorage.setItem(USER_ID_ATTR, data)
    state.user_id = data
  },
  SET_CLIENT_ID: (state, data) => {
    localStorage.setItem(CLIENT_ID_ATTR, data)
    state.client_id = data
  },
  resetState: (state) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, {
      isAuthenticated: false,
      accessToken: null,
      refreshToken: null,
      user_id: null
    })
  }
}

function setAuthResult(result, commit) {
  const user_id = VueJwtDecode.decode(result.IdToken).sub
  const client_id = VueJwtDecode.decode(result.AccessToken).client_id

  let account_id = VueJwtDecode.decode(result.AccessToken).iss
  const awsregex = /https:\/\/cognito-idp\.(us(-gov)?|ap|ca|cn|eu|sa)-(central|(north|south)?(east|west)?)-\d\.amazonaws\.com\//
  account_id = account_id.replace(awsregex, '')
  commit('SET_CLIENT_ID', client_id)
  commit('SET_ACCESS_TOKEN', result.AccessToken)
  commit('SET_USER_ID', user_id)
  if (result.RefreshToken) {
    commit('SET_REFRESH_TOKEN', result.RefreshToken)
  }
  commit('SET_ID_TOKEN', result.IdToken)
}

const actions = {
  login({ commit }, auth_data) {
    addDomainToStoredDomains(auth_data.domain, auth_data.account_name, auth_data.username)
    return new Promise((resolve, reject) => {
      login(auth_data.username, auth_data.password, auth_data.account_id, auth_data.domain).then(resp => {
        if (resp.data.AccessToken) {
          setAuthResult(resp.data, commit)
          resolve(resp.data)
        } else {
          resolve(resp.data)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  respond_to_auth_challenge({ commit }, challenge_data) {
    return new Promise((resolve, reject) => {
      respond_to_auth_challenge(
        challenge_data.challenge_name,
        challenge_data.client_id,
        challenge_data.session,
        challenge_data.password,
        challenge_data.username
      ).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      const idtoken = localStorage.getItem(ID_TOKEN_ATTR)
      const username = VueJwtDecode.decode(idtoken)['cognito:username']
      const client_id = localStorage.getItem(CLIENT_ID_ATTR)
      if (!client_id) {
        reject()
      }
      refresh(localStorage.getItem(REFRESH_TOKEN_ATTR), username, client_id).then(resp => {
        setAuthResult(resp.data, commit)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      clearStorage(true)
      commit('resetState')
      store.dispatch('account/resetState')
      store.dispatch('permission/resetState')
      store.dispatch('static_content/resetState')
      store.dispatch('myuser/resetState')
      store.dispatch('users/resetState')
      resetRouter()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

