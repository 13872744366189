<template>
  <span />
</template>

<script>
// we can either import Klaro without styles...
import * as klaro from 'klaro/dist/klaro-no-css'
// and the manually load the styles (e.g. to bundle them manually)
import '@/styles/klaro/klaro.scss'

import Vue from 'vue'
import Hotjar from 'vue-hotjar'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import i18n from '@/lang'

// we define a minimal configuration
export const klaro_config = {
  cookieExpiresAfterDays: 365,
  storageMethod: 'cookie',
  storageName: 'cc',
  default: false,
  htmlTexts: true,
  cookieDomain: process.env['NODE_ENV'] === 'development' ? 'localhost' : '.centrics.app',
  translations: {
    en: {
      poweredBy: i18n.t('cookieconsent.powered_by'),
      save: i18n.t('cookieconsent.buttons.save'),
      decline: i18n.t('cookieconsent.buttons.decline'),
      ok: i18n.t('cookieconsent.buttons.ok'),
      acceptSelected: i18n.t('cookieconsent.buttons.accept_selected'),
      consentNotice: {
        learnMore: i18n.t('cookieconsent.text.notice.learn_more'),
        description: i18n.t('cookieconsent.text.notice.description'),
        changeDescription: i18n.t('cookieconsent.text.notice.description_changed')
      },
      consentModal: {
        description: i18n.t('cookieconsent.text.modal.description'),
        title: i18n.t('cookieconsent.text.modal.title')
      },
      purposeItem: {
        service: i18n.t('cookieconsent.text.modal.purpose.service'),
        services: i18n.t('cookieconsent.text.modal.purpose.services')
      },
      service: {
        purpose: i18n.t('cookieconsent.text.modal.purpose.label.purpose'),
        purposes: i18n.t('cookieconsent.text.modal.purpose.label.purposes')
      },
      hotjar: {
        title: i18n.t('cookieconsent.text.modal.services.hotjar.title'),
        description: i18n.t('cookieconsent.text.modal.services.hotjar.description')
      },
      bugsnag: {
        title: i18n.t('cookieconsent.text.modal.services.bugsnag.title'),
        description: i18n.t('cookieconsent.text.modal.services.bugsnag.description')
      },
      purposes: {
        analytics: i18n.t('cookieconsent.text.modal.purpose.options.analytics')
      }
    }
  },
  services: [
    {
      name: 'hotjar',
      purposes: ['analytics']
    },
    {
      name: 'bugsnag',
      purposes: ['analytics']
    }
  ]
}

export default {
  name: 'CookieConsent',
  mounted() {
    // we assign the Klaro module to the window, so that we can access it in JS
    window.klaro = klaro
    window.klaroConfig = klaro_config
    // we set up Klaro with the config
    klaro.setup(klaro_config)
    const manager = klaro.getManager(klaro_config)
    const consents = manager.consents
    if (consents.bugsnag) {
      Bugsnag.start({
        apiKey: '770dc896eeaff38d70c86233ba9b6c79',
        plugins: [new BugsnagPluginVue()],
        logger: null
      })
    }

    if (consents.hotjar) {
      Vue.use(Hotjar, {
        id: '1875440' // Hotjar Site ID
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/element-variables.scss";
  .Cookie--base {
    padding: 10px !important;
  }
</style>
