import request from '@/utils/request'

export function updateAccount(data) {
  return request({
    url: '/api/v2/account',
    method: 'patch',
    data: data
  })
}

export function acceptTC(accepted) {
  return request({
    url: '/api/v2/account/terms',
    method: 'post',
    data: { terms: accepted }
  })
}

export function register(name, first_name, last_name, username, email, terms, recaptcha_token) {
  return request({
    url: '/api/v2/accounts/register',
    method: 'post',
    data: {
      token: recaptcha_token,
      name: name,
      first_name: first_name,
      last_name: last_name,
      username: username,
      email: email,
      terms: terms
    }
  })
}

export function getAccount() {
  return request({
    url: '/api/v2/account',
    method: 'get'
  })
}
