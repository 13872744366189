import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import * as klaro from 'klaro/dist/klaro-no-css'
import { klaro_config } from '@/CookieConsent'

Vue.config.errorHandler = function(err, vm, info, a) {
// Don't ask me why I use Vue.nextTick, it just a hack.
// detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
  Vue.nextTick(() => {
    const manager = window.klaro.getManager(window.klaroConfig)
    const consents = manager.consents
    if (consents.bugsnag) {
      Bugsnag.notify(err)
    }
  })
}
