// import Cookies from 'js-cookie'
import {
  ID_TOKEN_ATTR,
  REFRESH_TOKEN_ATTR,
  USER_ID_ATTR
} from '@/store/modules/auth'
import { DOMAIN_ATTR } from './auth'

export const CACHE_KEY_PREFIX = 'cah-'

export function getItem(key) {
  const cache_key = CACHE_KEY_PREFIX + key
  const itemStr = localStorage.getItem(cache_key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(cache_key)
    return null
  }
  return item.value
}

export function setItem(key, value, ttl = 60 * 2) { // 120 seconds --> 2 minutes
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl * 1000
  }
  const cache_key = CACHE_KEY_PREFIX + key
  return localStorage.setItem(cache_key, JSON.stringify(item))
}

export function removeItem(key) {
  const cache_key = CACHE_KEY_PREFIX + key
  return localStorage.removeItem(cache_key)
}

export function clearStorage(all = false) {
  const never_delete = [
    DOMAIN_ATTR
  ]
  const delete_only_on_force_delete = [
    REFRESH_TOKEN_ATTR,
    ID_TOKEN_ATTR,
    USER_ID_ATTR
  ]
  const arr = [] // Array to hold the keys
  // Iterate over localStorage and insert the keys that meet the condition into arr
  for (let i = 0; i < localStorage.length; i++) {
    if ((all || !delete_only_on_force_delete.find(d => d === localStorage.key(i))) &&
      !never_delete.find(d => d === localStorage.key(i))) {
      arr.push(localStorage.key(i))
    }
  }
  // Iterate over arr and remove the items by key
  for (let j = 0; j < arr.length; j++) {
    localStorage.removeItem(arr[j])
  }
}

export function clearCachedItems() {
  const arr = [] // Array to hold the keys
  // Iterate over localStorage and insert the keys that meet the condition into arr
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, 3) === CACHE_KEY_PREFIX) {
      arr.push(localStorage.key(i))
    }
  }

  // Iterate over arr and remove the items by key
  for (var i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i])
  }
}
