import { getAccounts, getAccountInfos, getMembers, updateAccountLogo, changeMemberStatus } from '@/api/account'

import VueJwtDecode from 'vue-jwt-decode'
import { setCurrentAccount } from '@/utils/account-manager'
import store from '../index'
import { CURRENT_ACCOUNT_SIGNATURE_ATTR } from '../../utils/account-manager'

const state = {
  accounts: []
}

const mutations = {
  SET_ALL_ACCOUNTS: (state, accounts) => {
    state.accounts = accounts
  }
}

const actions = {
  resetState({ commit }) {
    commit('SET_ALL_ACCOUNTS', [])
  },
  getAllAccounts({ commit }, force = false) {
    if (force) {
      commit('SET_ALL_ACCOUNTS', [])
    }
    return new Promise((resolve, reject) => {
      if (state.accounts.length) {
        resolve(state.accounts)
      } else {
        getAccounts().then(resp => {
          commit('SET_ALL_ACCOUNTS', resp.data.data)
          resolve(resp.data.data)
        }).catch(err => {
          reject(err)
        })
      }
    })
  },
  getCurrentAccount({ commit, dispatch }, force = false) {
    if (force) {
      localStorage.removeItem(CURRENT_ACCOUNT_SIGNATURE_ATTR)
    }
    return new Promise((resolve, reject) => {
      let signature = localStorage.getItem(CURRENT_ACCOUNT_SIGNATURE_ATTR)
      let account_data
      try {
        account_data = VueJwtDecode.decode(signature)
      } catch (error) {
        // signature invalid because couldn't be opened
        signature = null
      }
      if (signature) {
        store.dispatch('permission/setRoles', account_data.roles)
        const data = {
          signature: signature,
          data: account_data
        }
        resolve(data)
      } else {
        if (store.getters.user_id) {
          getAccounts().then(resp => {
            if (resp.data.data.length === 1) {
              const account = resp.data.data[0]
              const signature = account.signature
              setCurrentAccount(signature)
              const account_data = {
                signature: signature,
                data: VueJwtDecode.decode(signature)
              }
              resolve(account_data)
            } else {
              reject('should only have one account')
            }
          }).catch(err => {
            reject(err)
          })
        } else {
          reject('not authenticated')
        }
      }
    })
  },
  getAccountDetails({ commit }) {
    return new Promise((resolve, reject) => {
      getAccountInfos().then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  changeMemberStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      changeMemberStatus(data.user_id, data.status).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

