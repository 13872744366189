import hasProtocolsApp from './has-protocols-app'

const install = function(Vue) {
  Vue.directive('has-protocols-app', hasProtocolsApp)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

hasProtocolsApp.install = install
export default hasProtocolsApp
